<template>
  <el-tooltip
    transition=""
    :open-delay="openDelay"
    :effect="effect"
    :placement="placement"
    :popper-class="popperClasses"
    :content="content"
    :visible-arrow="visibleArrow"
    :disabled="disabled"
  >
    <template #content>
      <slot name="content" />
    </template>
    <slot />
  </el-tooltip>
</template>

<script>
export default {
  name: 'IbTooltip',

  props: {
    content: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'top'
    },
    popperClass: {
      type: String,
      default: null
    },
    openDelay: {
      type: Number,
      default: 700
    },
    visibleArrow: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onDialog: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    popperClasses () {
      return [
        this.popperClass,
        this.dialogClass
      ].join(' ')
    },

    dialogClass () {
      return this.onDialog ? 'dialog' : null
    },

    effect () {
      return this.dark ? 'dark' : 'light'
    }
  }
}
</script>
