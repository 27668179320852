<template>
  <el-form-item
    class="ib-input input-inside-label"
    :class="{'focused': focused || val}"
    :label="label"
  >
    <el-input
      v-model="val"
      placeholder=""
      v-bind="$attrs"
      :maxlength="maxlength"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    />
  </el-form-item>
</template>

<script>
import _toUpper from 'lodash/toUpper'

export default {
  name: 'IbInput',

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || value === null,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    maxlength: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      focused: false
    }
  },

  computed: {
    val: {
      get () {
        return this.uppercase ? _toUpper(this.value) : this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    val (value) {
      if (!value && !this.focused) {
        this.focused = false
      }
    }
  },

  methods: {
    onFocus () {
      this.focused = true
      this.$emit('focus')
    },

    onBlur () {
      this.focused = false
      this.$emit('blur')
    }
  }
}
</script>
