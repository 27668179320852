
import Vuetify, {
  VAlert,
  VApp,
  VAutocomplete,
  VAvatar,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VChip,
  VChipGroup,
  VCol,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VForm,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemGroup,
  VListItemTitle,
  VMenu,
  VPagination,
  VRow,
  VSelect,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VTextField,
  VTextarea,
  VTooltip
} from 'vuetify/lib'

import Vue from 'vue'

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAutocomplete,
    VAvatar,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VChip,
    VChipGroup,
    VCol,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VForm,
    VIcon,
    VList,
    VListItem,
    VListItemContent,
    VListItemGroup,
    VListItemTitle,
    VMenu,
    VPagination,
    VRow,
    VSelect,
    VSwitch,
    VTab,
    VTabItem,
    VTabs,
    VTabsItems,
    VTabsSlider,
    VTextField,
    VTextarea,
    VTooltip
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#0d6efd',
        accent: '#0b42b0',
        secondary: '#8A8D93',
        success: '#40D277',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        background: '#f5f6fa',
        border: '#d9d9d9',
        ai: '#8509F6'
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
    // scrollBarWidth: 24
  }
})
