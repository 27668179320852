<template>
  <div class="mx-md-5 text-center">
    <div
      v-if="showIllustration"
      class="illustration-wrapper mb-5"
    >
      <img class="h-100 w-100" :src="image" alt="">
    </div>

    <h1 class="header">
      {{ title }}
    </h1>

    <p v-if="subtitle" class="text-lead">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'IbDialogConfirmationContent',

  props: {
    image: {
      type: String,
      required: false,
      default: null
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: null
    },

    showIllustration: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.header {
  font-size: 2.25rem;
}

.text-lead {
  opacity: 0.5;
  font-size: 1.5rem;
}

.illustration-wrapper {
  overflow: hidden;
  position: relative;
  height: 240px;
  text-align: center;
}
</style>
