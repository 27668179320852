<template>
  <div class="wrapper text-center d-flex justify-content-center align-items-center">
    <div class="position-relative">
      <ib-button
        class="text-uppercase"
        font-family="regular"
        :transition="false"
        @click="$emit('upgrade')"
      >
        <template #icon-left>
          <i class="icon ib-icon ib-icon-lock" />
        </template>
        {{ $t('upgradeToUnlock') }}
      </ib-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IbBlurredBox'
}
</script>

<style scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  backdrop-filter: blur(10px);
  width: 100%;
  margin: 0 auto;
  border-radius: 15px;
}
</style>
