import Vue from 'vue'

import IbBlurredBox from '@/components/_v2/Common/IbBlurredBox'
import IbButton from '@/components/_v2/Button/IbButton'
import IbButtonBasic from '@/components/_v2/Button/IbButtonBasic.vue'
import IbButtonSecondary from '@/components/_v2/Button/IbButtonSecondary.vue'
import IbButtonTab from '@/components/_v2/Button/IbButtonTab.vue'
import IbCard from '@/components/_v2/IbCard'
import IbCheckmark from '@/components/_v2/IbCheckmark'
import IbColorPicker from '@/components/_v2/ColorPicker/IbColorPicker'
import IbDialog from '@/components/_v2/Dialog/IbDialog'
import IbDialogConfirmationContent from '@/components/_v2/Dialog/IbDialogConfirmationContent'
import IbDialogContainer from '@/components/_v2/Dialog/IbDialogContainer'
import IbDialogFooter from '@/components/_v2/Dialog/IbDialogFooter'
import IbDivider from '@/components/_v2/Common/IbDivider'
import IbDraggable from '@/components/_v2/Common/IbDraggable'
import IbExpandPanel from '@/components/_v2/IbExpandPanel/IbExpandPanel.vue'
import IbIdeaAvatar from '@/components/_v2/IbIdeaAvatar'
import IbInput from '@/components/_v2/IbInput'
import IbLoader from '@/components/_v2/IbLoader'
import IbPill from '@/components/_v2/Common/IbPill'
import IbProgressDashboard from '@/components/_v2/IbProgressDashboard'
import IbSelect from '@/components/_v2/Select/IbSelect'
import IbSingleAccordion from '@/components/_v2/IbSingleAccordion.vue'
import IbSup from '@/components/_v2/Common/IbSup'
import IbTag from '@/components/_v2/Common/IbTag'
import IbTooltip from '@/components/_v2/IbTooltip'
import IbUserAvatar from '@/components/_v2/IbUserAvatar'
import IbVuetifyApp from '@/components/_v2/IbVuetifyApp'

Vue.component('IbBlurredBox', IbBlurredBox)
Vue.component('IbButton', IbButton)
Vue.component('IbButtonBasic', IbButtonBasic)
Vue.component('IbButtonSecondary', IbButtonSecondary)
Vue.component('IbButtonTab', IbButtonTab)
Vue.component('IbCard', IbCard)
Vue.component('IbCheckmark', IbCheckmark)
Vue.component('IbColorPicker', IbColorPicker)
Vue.component('IbDialog', IbDialog)
Vue.component('IbDialogConfirmationContent', IbDialogConfirmationContent)
Vue.component('IbDialogContainer', IbDialogContainer)
Vue.component('IbDialogFooter', IbDialogFooter)
Vue.component('IbDivider', IbDivider)
Vue.component('IbDraggable', IbDraggable)
Vue.component('IbExpandPanel', IbExpandPanel)
Vue.component('IbIdeaAvatar', IbIdeaAvatar)
Vue.component('IbInput', IbInput)
Vue.component('IbLoader', IbLoader)
Vue.component('IbPill', IbPill)
Vue.component('IbProgressDashboard', IbProgressDashboard)
Vue.component('IbSelect', IbSelect)
Vue.component('IbSingleAccordion', IbSingleAccordion)
Vue.component('IbSup', IbSup)
Vue.component('IbTag', IbTag)
Vue.component('IbTooltip', IbTooltip)
Vue.component('IbUserAvatar', IbUserAvatar)
Vue.component('IbVuetifyApp', IbVuetifyApp)
