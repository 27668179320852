import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend,
  localize
} from 'vee-validate'

import {
  confirmed,
  email,
  max,
  min,
  numeric,
  required
} from 'vee-validate/dist/rules'

import Vue from 'vue'
import de from 'vee-validate/dist/locale/de.json'
import en from 'vee-validate/dist/locale/en.json'
import es from 'vee-validate/dist/locale/es.json'
import fr from 'vee-validate/dist/locale/fr.json'
import it from 'vee-validate/dist/locale/it.json'
import nl from 'vee-validate/dist/locale/nl.json'
import pl from 'vee-validate/dist/locale/pl.json'
import pt from 'vee-validate/dist/locale/pt_PT.json'
import sr from 'vee-validate/dist/locale/sr_Latin.json'

import deValidationNames from '@/locales/validation/de'
import enValidationNames from '@/locales/validation/en'
import esValidationNames from '@/locales/validation/es'
import frValidationNames from '@/locales/validation/fr'
import itValidationNames from '@/locales/validation/it'
import nlValidationNames from '@/locales/validation/nl'
import plValidationNames from '@/locales/validation/pl'
import ptValidationNames from '@/locales/validation/pt'
import srValidationNames from '@/locales/validation/sr'

localize({
  de: {
    ...de,
    ...deValidationNames
  },
  en: {
    ...en,
    ...enValidationNames
  },
  es: {
    ...es,
    ...esValidationNames
  },
  fr: {
    ...fr,
    ...frValidationNames
  },
  it: {
    ...it,
    ...itValidationNames
  },
  nl: {
    ...nl,
    ...nlValidationNames
  },
  pl: {
    ...pl,
    ...plValidationNames
  },
  pt: {
    ...pt,
    ...ptValidationNames
  },
  sr: {
    ...sr,
    ...srValidationNames
  }
})

// import i18n from '@/i18n'

configure({
  mode: 'aggressive'
  // defaultMessage: (field, values) => {
  //   console.log('field', field)
  //   values._rule_ = i18n.t(`validations.${field}`)
  //   console.log('values', values)
  //
  //   return i18n.t(`${values._rule_}`, values)
  // }
})

// built-in validators
extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('min', min)
extend('max', max)
extend('numeric', numeric)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
