import axios from 'axios'

const getDefaultState = () => {
  return {
    data: {
      id: 0,
      percentage: 0,
      value: [],
      awardActivated: false
    }
  }
}

const state = getDefaultState()

const actions = {
  fetchGamification ({ commit }) {
    return axios.get('/users/gamification')
      .then(response => response.data.payload.data)
      .then(response => {
        if (response) {
          commit('setGamification', response)
        }

        return response
      })
  },

  unlockReward ({ commit }) {
    return axios.post('/users/gamification/claim-reward')
      .then(response => response.data.payload)
      .then(response => {
        commit('setGamification', response.data)

        return response
      })
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setGamification (state, gamification) {
    state.data = gamification
  }
}

const getters = {
  hasGamification: (state) => {
    return state.data.id !== 0
  },

  getItems: (state) => {
    return state.data.value
  },

  getPercentage: (state) => {
    return state.data.percentage
  },

  getCountCompletedItems: (state) => {
    return state.data.value.filter(item => item.complete).length
  },

  isAwardActivated: (state) => {
    return state.data.awardActivated
  },

  hasGamificationAndAwardNotActivated: (state) => {
    if (state.data.id === 0) return false

    return !state.data.awardActivated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
