const getDefaultState = () => {
  return {
    data: [
      {
        classColor: 'text-yellow',
        icon: require('@/assets/img/icons/features/idea-planIcon.svg'),
        name: 'sidebar.ideaPlan',
        title: 'pages.pricing.textVideo.idea-plan.title',
        description: 'pages.pricing.textVideo.idea-plan.description',
        videoName: 'explainer_IdeaPlan',
        reverse: false
      },
      {
        classColor: 'text-red-light',
        icon: require('@/assets/img/icons/features/business-guideIcon.svg'),
        name: 'sidebar.businessGuide',
        title: 'pages.pricing.textVideo.business-guide.title',
        description: 'pages.pricing.textVideo.business-guide.description',
        videoName: 'Intro1_StoryMode',
        reverse: true
      },
      {
        classColor: 'text-green-light',
        icon: require('@/assets/img/icons/features/financial-planIcon.svg'),
        name: 'sidebar.financialPlan',
        title: 'pages.pricing.textVideo.financial-plan.title',
        description: 'pages.pricing.textVideo.financial-plan.description',
        videoName: 'explainer_FinancialPlan',
        reverse: false
      },
      {
        classColor: 'text-blue-sky',
        icon: require('@/assets/img/icons/features/idea-validationIcon.svg'),
        name: 'sidebar.ideaValidation',
        title: 'pages.pricing.textVideo.idea-validation.title',
        description: 'pages.pricing.textVideo.idea-validation.description',
        videoName: 'idea_validationVideo',
        reverse: true
      },
      {
        classColor: 'text-blue-dark',
        icon: require('@/assets/img/icons/features/business-planIcon.svg'),
        name: 'sidebar.businessPlan',
        title: 'pages.pricing.textVideo.business-plan.title',
        description: 'pages.pricing.textVideo.business-plan.description',
        videoName: 'explainer_BusinessPlan',
        reverse: false
      }
    ]
  }
}

const state = getDefaultState()

const getters = {
  getFeaturesData (state) {
    return state.data
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
