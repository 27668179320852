<template>
  <v-app :id="randomId">
    <slot />
  </v-app>
</template>

<script>
export default {
  name: 'IbVuetifyApp',

  data () {
    return {
      randomId: null
    }
  },

  mounted () {
    this.randomId = this.generateRandomId()
  },

  methods: {
    generateRandomId () {
      return Math.random()
        .toString(36)
        .substring(7)
    }
  }
}
</script>
