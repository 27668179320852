import UserPlanEnum from '@/constants/UserPlanEnum'
import Vue from 'vue'
import axios from 'axios'
import collaborators from './collaborators'
import gamification from '@/store/modules/user/gamification'
import { getters } from './getters'

const getDefaultState = () => {
  return {
    id: 0,
    email: '',
    firstTime: false,
    hash: null,
    firstName: '',
    lastName: '',
    isVerified: false,
    aiRequests: {
      requests: 0,
      spent: 0,
      additionalRequests: 0,
      additionalRequestsSpent: 0,
      amount: 0,
      amountSpent: 0
    },
    subscription: {
      plan: UserPlanEnum.DREAMER,
      ideas: 1,
      users: 1
    },
    isLifetimeSubscription: false,
    templateAdmin: false,
    status: '',
    image: '',
    images: null,
    gender: '',
    age: '',
    country: '',
    city: '',
    tutorial: {
      mainCompleted: false,
      sidebarIdeaCompleted: false,
      mainBottomBarCompleted: false,
      guideAndExampleCompleted: false,
      addMoreDetailsCompleted: false,
      uniqueSellingPropositionCompleted: false,
      productCompleted: false,
      promotionCompleted: false,
      competitorsCompleted: false,
      managementTeamCompleted: false,
      startupCostsCompleted: false,
      employeesAndPayrollCompleted: false,
      distributionCompleted: false
    },
    guideDialog: {
      addProductGuide: false,
      addPromotionGuide: false,
      addDistributionGuide: false,
      addPartnerGuide: false,
      addCustomerGuide: false,
      addCompetitorGuide: false,
      addMarketPotentialGuide: false,
      addMarketPotentialAvailableGuide: false,
      addMarketPotentialShareGuide: false,
      addStartupCostGuide: false,
      addFinancingGuide: false,
      addManagementTeamGuide: false,
      addStaffPayrollGuide: false,
      addOwnershipGuide: false,
      addRevenueStreamGuide: false,
      addDirectCostGuide: false,
      addMarketingBudgetGuide: false,
      addOtherOverheadGuide: false,
      addDividendGuide: false
    },
    ideasCount: 0,
    isAppSumoUser: false,
    invited: false,
    subscribed: false,
    freeTrial: null,
    notifications: {
      notifications: []
    },
    quickTours: {
      ideaPlanQuickTour: false,
      businessGuideQuickTour: false,
      businessPlanQuickTour: false,
      financialPlanQuickTour: false
    },
    guideTutorial: {
      guideTutorialStoryMode: false
    },
    ideaPlanGuide: {
      ideaPlanGuideTutorial: false
    },
    journalGuide: {
      journalGuideTutorial: false
    }
  }
}

const state = getDefaultState()

// noinspection JSUnusedGlobalSymbols
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setUser (state, payload) {
    const { user } = payload

    state.id = user.id
    state.email = user.email
    state.hash = user.hash
    state.firstName = user.firstName
    state.lastName = user.lastName
    state.isVerified = user.isVerified
    state.status = user.status
    state.image = user.image ? user.image.path + user.image.filename : ''
    state.images = user.image ? user.image.sizes : null
    state.gender = user.gender
    state.age = user.age
    state.country = user.country
    state.city = user.city
    state.tutorial = user.tutorial
    state.guideDialog = user.guideDialog
    state.ideasCount = user.ideas.length
    state.isAppSumoUser = user.isAppSumoUser
    state.invited = user.invited
    state.subscribed = user.subscribed
    state.freeTrial = user.freeTrial
    state.notifications.notifications = user.notification
    state.guideTutorial = user.guideTutorial
    state.ideaPlanGuide = user.ideaPlanGuide
    state.journalGuide = user.journalGuide
    state.firstTime = user.firstTime
    state.templateAdmin = !!user.templateAdmin
    state.collaborators = { items: user.collaborators }
    state.quickTours = user.quickTours.data
    state.isLifetimeSubscription = user.isLifetimeSubscription

    if (user.aiRequests) {
      state.aiRequests = user.aiRequests
    }

    if (user.gamification) {
      state.gamification.data = user.gamification
    }

    state.subscription = user.subscription
      ? user.subscription
      : {
          plan: UserPlanEnum.DREAMER,
          ideas: 1,
          users: 1
        }
  },

  setMyProfile (state, payload) {
    const { firstName, lastName, age, gender, country, city, email, status, image } = payload
    state.firstName = firstName
    state.lastName = lastName
    state.age = age
    state.gender = gender
    state.country = country
    state.city = city
    state.email = email
    state.status = status
    state.image = image ? `${image.path}${image.filename}` : ''
    state.images = image ? image.sizes : null
  },

  setSubscription (state, payload) {
    Vue.set(state, 'subscription', payload.subscription)
    state.subscribed = true
    state.freeTrial = null
  },

  setUserEmail (state, payload) {
    state.email = payload.email
  },

  setFirstName (state, firstName) {
    state.firstName = firstName
  },

  setLastName (state, lastName) {
    state.lastName = lastName
  },

  setTutorial (state, payload) {
    state.tutorial = payload.tutorial
  },

  setGuideDialog (state, payload) {
    state.guideDialog = payload.guideDialog
  },

  setQuickTours (state, payload) {
    state.quickTours = payload
  },

  increaseIdeasCount (state) {
    state.ideasCount++
  },

  decreaseIdeasCount (state) {
    state.ideasCount--
  },

  increaseAiRequests (state) {
    state.aiRequests.spent++
  },

  setNotification (state, notification) {
    state.notifications.notifications.unshift(notification)
  },

  setGuideTutorial (state, payload) {
    state.guideTutorial = payload.guideTutorial
  },

  setIdeaPlanGuideTutorial (state, payload) {
    state.ideaPlanGuide = payload.ideaPlanGuide
  },

  setJournalGuideTutorial (state, payload) {
    state.journalGuide = payload.journalGuide
  },

  setAiRequests (state, payload) {
    state.aiRequests = payload
  },

  setFirstTime (state, payload) {
    state.firstTime = payload
  },

  setFreeTrial (state, freeTrial) {
    state.freeTrial = freeTrial
  }
}

const actions = {
  refreshUserData ({ commit }) {
    return axios.get('users/current')
      .then(response => {
        const user = response.data.payload.user
        commit('setUser', { user })

        this._vm.$intercom.trackEvent('Logged in')

        if (user.selectedIdea) {
          commit('idea/setIdea', { idea: user.selectedIdea }, { root: true })
        } else {
          commit('idea/resetState', null, { root: true })
        }
        if (user.ideas) {
          commit('ideaCollection/setIdeas', { ideas: user.ideas }, { root: true })
        }
        if (user.sharedIdeas) {
          commit('ideaCollection/setSharedIdeas', user.sharedIdeas, { root: true })
        }

        return response
      })
  },

  getMyProfile ({ commit }) {
    return axios.get('my-profile')
      .then(response => response.data.payload)
      .then(response => {
        commit('setMyProfile', response.user)
        if (response.user.subscription) {
          commit('setSubscription', response.user)
        }

        return response
      })
  },

  getCurrentUser ({ commit }) {
    return axios.get('users/current')
      .then(response => response.data.payload)
      .then(response => {
        commit('setUser', { user: response.user })

        return response
      })
  },

  updateUser ({ commit }, user) {
    return axios.put('users', user)
      .then(response => response.data.payload)
      .then(response => {
        const { user, token } = response
        commit('setUser', { user })

        if (token) {
          axios.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
          document.cookie = `access_token=${token}; path=/; secure`
        }

        return response
      })
  },

  updateNotification ({ commit }, notification) {
    return axios.put('story-mode/common/notification', notification)
      .then(response => {
        commit('setNotification', response.data.payload.notifications)

        return response
      })
  },

  emailVerification ({ commit }, data) {
    return axios.post('authentication/verification', data)
      .then(response => response.data.payload)
      .then(response => {
        const { user } = response
        commit('setUser', { user })

        return response
      })
  },

  resendEmailVerification ({ commit }, data) {
    return axios.post('authentication/resend-verification', data)
      .then(response => response.data.payload)
      .then(response => {
        return response
      })
  },

  fetchAiRequests ({ commit }) {
    return axios.get('ai/requests')
      .then(response => response.data.payload)
      .then(response => {
        commit('setAiRequests', response.data)
        commit('idea/setOwnerAiRequests', response.ownerAiRequests, { root: true })

        return response
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    collaborators,
    gamification
  }
}
