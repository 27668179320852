<template>
  <draggable
    v-model="model"
    class="ib-draggable"
    animation="500"
    :group="group"
    :draggable="draggable"
    v-bind="allOptions"
    @start="$emit('start')"
    @end="$emit('end')"
  >
    <!-- Header Slot-->
    <template slot="header">
      <slot name="header" />
    </template>
    <!-- /Header Slot-->
    <!-- Default Slot-->
    <slot />
    <!-- /Default Slot-->
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'IbDraggable',

  components: {
    Draggable
  },

  props: {
    list: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    group: {
      type: String,
      default: null
    },
    draggable: {
      type: String,
      default: '.draggable'
    }
  },

  data () {
    return {
      defaultOptions: {
        delay: 100,
        delayOnTouchOnly: true
      }
    }
  },

  computed: {
    model: {
      get () {
        return this.list
      },
      set (value) {
        this.$emit('change', value)
      }
    },

    allOptions () {
      return { ...this.defaultOptions, ...this.options }
    }
  }
}
</script>

<style scoped>
.ib-draggable >>> .sortable-ghost {
  opacity: 0;
}
</style>
