export default [
  {
    path: '/select-idea',
    name: 'select-idea',
    meta: {
      title: 'Select Idea',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "select-idea-new" */ '@/views/Onboarding/Pages/OnboardingPage.vue').then(value => value.default)
  }
]
