<template>
  <div class="m-auto" :style="{maxWidth: maxWidth}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IbDialogContainer',

  props: {
    maxWidth: {
      type: String,
      default: '970px'
    }
  }
}
</script>
