function getImageBySize (images, size) {
  if (images) {
    return images[size] ?? images.original
  }

  return null
}

export {
  getImageBySize
}
