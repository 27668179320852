export const SocialAuthOptions = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  tokenPath: 'payload',
  token: 'social_token',
  providers: {
    // Facebook app login
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: process.env.VUE_APP_BASE_URL,
      responseType: 'code',
      authorizationEndpoint: process.env.VUE_APP_FACEBOOK_AUTH_ENDPOINT,
      requiredUrlParams: ['display', 'scope'],
      scope: ['email'],
      display: 'popup',
      oauthType: '2.0'
    },
    // LinkedIn app login
    linkedin: {
      name: 'linkedin',
      clientId: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
      url: '/api/v1/authentication/signup/linkedin',
      authorizationEndpoint: process.env.VUE_APP_LINKEDIN_AUTH_ENDPOINT,
      redirectUri: process.env.VUE_APP_BASE_URL,
      requiredUrlParams: ['state', 'scope'],
      scope: ['r_emailaddress r_liteprofile'],
      scopeDelimiter: ' ',
      state: 'STATE',
      oauthType: '2.0',
      popupOptions: { width: 527, height: 582 }
    },
    // Google app login
    google: {
      name: 'google',
      url: '/api/v1/authentication/signup/google',
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      authorizationEndpoint: process.env.VUE_APP_GOOGLE_AUTH_ENDPOINT,
      redirectUri: process.env.VUE_APP_BASE_URL,
      requiredUrlParams: ['scope'],
      optionalUrlParams: ['display'],
      scope: ['profile', 'email'],
      scopePrefix: 'openid',
      scopeDelimiter: ' ',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 452, height: 633 }
    }
  },
  bindRequestInterceptor: function () {
    this.$http.interceptors.request.use((config) => {
      if (this.isAuthenticated()) {
        config.headers['X-Authorization'] = `Bearer ${window.$cookies.get('access_token')}`
      } else {
        delete config.headers['X-Authorization']
      }

      return config
    })
  },
  bindResponseInterceptor: function () {
    this.$http.interceptors.response.use((response) => {
      this.setToken(response)

      return response
    })
  }
}
