<template>
  <div>
    <hr
      class="m-auto"
      :class="classes"
    >
  </div>
</template>

<script>
export default {
  name: 'IbDivider',

  props: {
    block: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        'w-75': !this.block,
        'w-100': this.block
      }
    }
  }
}
</script>

<style scoped lang="scss">
hr {
  opacity: .25;
}
</style>
