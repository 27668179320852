<template>
  <button
    class="ib-button-secondary d-flex align-items-center"
    :class="classes"
    :style="{ height: height }"
    @click="onClick"
  >
    <!-- Icon -->
    <template v-if="!$slots['icon'] && icon">
      <i
        class="ib-button-icon mr-1 ib-icon"
        :class="[icon]"
        :style="{'font-size': iconSize}"
      />
    </template>
    <!-- /Icon -->

    <slot name="icon" />

    <span :style="{'font-size': fontSize}">
      <slot />
    </span>
  </button>
</template>

<script>
/* Colors */
const COLORS = ['blue', 'violet', 'gray']

/** Font Families **/
const FONT_FAMILIES = ['light', 'regular', 'medium', 'semi-bold', 'bold']

export default {
  name: 'IbButtonSecondary',

  props: {
    height: {
      type: String,
      default: '36px'
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'violet'
    },

    fontSize: {
      type: String,
      default: '16px'
    },

    iconSize: {
      type: String,
      default: '16px'
    },

    icon: {
      type: String,
      default: null
    },

    fontFamily: {
      type: String,
      validator: value => {
        return FONT_FAMILIES.includes(value)
      },
      default: 'medium'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        this.fontFamilyClass,
        { icon: this.icon },
        { disabled: this.disabled }
      ]
    },

    colorClass () {
      return `ib-button-secondary-${this.color}`
    },

    fontFamilyClass () {
      return `ib-button-secondary-${this.fontFamily}`
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.ib-button-secondary {
  transition: all 0.25s;
  border-radius: 8px;
  padding: 0 20px;

  &.ib-button-secondary-violet {
    color: $color-ai;
  }

  &.ib-button-secondary-blue {
    color: #0E63E6;
  }

 &:hover {
   background: #ECEDEF;
 }

  /* Font families */
  .ib-button-secondary-light {
    font-family: $outfit-light;
  }

  .ib-button-secondary-regular {
    font-family: $outfit-regular;
  }

  .ib-button-secondary-medium {
    font-family: $outfit-medium;
  }

  .ib-button-semi-bold {
    font-family: $outfit-semi-bold;
  }

  .ib-button-bold {
    font-family: $outfit-bold;
  }
}
</style>
