<template>
  <sup
    class="ib-sup"
    :class="classes"
  >
    <slot />
  </sup>
</template>

<script>
/** Colors **/
const COLORS = ['blue', 'yellow', 'black']

export default {
  name: 'IbSup',

  props: {
    variant: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'yellow'
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass
      ]
    },

    colorClass () {
      return `ib-sup-${this.variant}`
    }
  }
}
</script>

<style scoped lang="scss">
.ib-sup {
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: super;
  line-height: 12px;

  &.ib-sup-yellow {
    color: $color-yellow;
  }

  &.ib-sup-blue {
    color: $color-primary;
  }

  &.ib-sup-black {
    color: black;
  }
}
</style>
