<template>
  <div
    class="ib-expand-panel-wrapper"
    :class="[borderColorClass, inlineClass]"
  >
    <div
      class="ib-expand-panel-header"
      :class="{'cursor-pointer': expandable}"
      @click="onClick"
    >
      <div
        class="header-title font-outfit-regular align-items-center"
        :class="[colorClass]"
      >
        <i
          v-if="icon"
          class="icon ib-icon"
          :class="[colorClass, icon]"
        />
        <template v-if="title">
          {{ title }}
        </template>
        <!-- Title slot -->
        <slot name="title" />
        <!-- /Title slot -->
      </div>

      <div
        v-if="closable || expandable"
        class="arrow-icon-wrapper"
        :class="{'align-self-baseline': closeBtnMobileAlign, 'align-self-md-center': closeBtnMobileAlign}"
        @click="$emit('close')"
      >
        <i
          v-if="closable"
          class="arrow-icon ib-icon-close cursor-pointer"
        />
        <i
          v-if="expandable"
          class="arrow-icon ib-icon-expand-arrow-2"
          :class="{'rotate': content}"
        />
      </div>
    </div>
    <Transition name="slide">
      <div
        v-show="content"
        class="ib-expand-panel-content"
      >
        <div>
          <ib-divider block />
          <div class="slot-wrapper">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

/* Colors */
const COLORS = ['blue', 'light-blue', 'mid-blue', 'dark-blue', 'yellow', 'violet', 'red', 'silver', 'dark']

export default {
  name: 'IbExpandPanel',

  props: {
    value: {
      type: Boolean,
      required: true
    },

    inline: {
      type: Boolean,
      default: false
    },

    expandable: {
      type: Boolean,
      default: true
    },

    closable: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'blue'
    },

    title: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: null
    },

    closeBtnMobileAlign: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    colorClass () {
      return `ib-expand-panel-${this.color}-color`
    },

    borderColorClass () {
      return `ib-expand-panel-border-${this.color}-color`
    },

    inlineClass () {
      return this.inline ? 'inline' : null
    }
  },

  methods: {
    onClick () {
      if (!this.expandable) return
      this.content = !this.content
      this.$emit('click', this.content)
    }
  }
}
</script>

<style scoped lang="scss">
.ib-expand-panel-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 0 20px 0 10px;
  border-left: 5px solid;

  &.inline {
    box-shadow: unset;
    border-left: unset;
  }

  .ib-expand-panel-header {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //cursor: pointer;

    .header-title {
      display: flex;
      align-items: self-end;
      font-size: 17px;
      flex-grow: 1;

      .icon {
        font-size: 26px;
        padding: 0 8px 0 5px;
      }
    }

    .arrow-icon-wrapper {
      padding: 7px 0;
      border-radius: 5px;
      transition: all 0.25s;
      //align-self: baseline;

      //@include media-breakpoint-up($md) {
      //  align-self: center;
      //}

      &:hover {
        background: #E8E9ED;
      }

      .arrow-icon {
        font-size: 20px;
        color: #A6A3A1;

        &:before {
          transition: transform .3s;
        }

        &.rotate {
          &:before {
            transform: rotate(-180deg);
            transition: transform .3s;
          }
        }
      }
    }
  }

  .ib-expand-panel-content {
    padding-left: 15px;
    padding-right: 5px;

    .slot-wrapper {
      padding: 30px 0 20px 0;
    }
  }
}

.ib-expand-panel-blue-color {
  color: #0E63E6;
}

.ib-expand-panel-border-blue-color {
  border-color: #0E63E6;
}

.ib-expand-panel-light-blue-color {
  color: #00BEFF;
}

.ib-expand-panel-border-light-blue-color {
  border-color: #00BEFF;
}

.ib-expand-panel-mid-blue-color {
  color: #0091FF;
}

.ib-expand-panel-border-mid-blue-color {
  border-color: #0091FF;
}

.ib-expand-panel-dark-blue-color {
  color: #0A42AF;
}

.ib-expand-panel-border-dark-blue-color {
  border-color: #0A42AF;
}

.ib-expand-panel-yellow-color {
  color: #FFB900;
}

.ib-expand-panel-border-yellow-color {
  border-color: #FFB900;
}

.ib-expand-panel-violet-color {
  color: #8509F6;
}

.ib-expand-panel-border-violet-color {
  border-color: #8509F6;
}

.ib-expand-panel-red-color {
  color: #FF7875;
}

.ib-expand-panel-border-red-color {
  border-color: #FF7875;
}

.ib-expand-panel-silver-color {
  color: #BBC9E4;
}

.ib-expand-panel-border-silver-color {
  border-color: #BBC9E4;
}

.ib-expand-panel-dark-color {
  color: #081E4A;
}

.ib-expand-panel-border-dark-color {
  border-color: #081E4A;
}
</style>
