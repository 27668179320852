import { render, staticRenderFns } from "./IbColorPicker.vue?vue&type=template&id=5f5125d3&scoped=true"
import script from "./IbColorPicker.vue?vue&type=script&lang=js"
export * from "./IbColorPicker.vue?vue&type=script&lang=js"
import style0 from "./IbColorPicker.vue?vue&type=style&index=0&id=5f5125d3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5125d3",
  null
  
)

export default component.exports