import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'

import { getDefaultErrorMessage } from '@/helpers/errorMessageHelpers'

function errorHandler (error) {
  if (axios.isCancel(error) || !store.getters['user/isLoggedIn']) return
  const errorCode = error.response.status
  const errorResponse = error.response

  switch (errorCode) {
    case 402:
    case 417:
      // Owner of idea is not subscribed anymore
      if (store.getters['idea/getIdeaId']) {
        store.commit('idea/resetState')
        Vue.prototype.$notify.close()
        Vue.prototype.$notify(getDefaultErrorMessage(errorResponse))
        router.push({ name: 'idea-collection' })
      }
      break
    default:
      Vue.prototype.$notify(getDefaultErrorMessage(errorResponse))
  }
}

function redirectHandler (error) {
  const route = router.currentRoute.name === 'payment-lifetime-deal' ? '/payment/lifetime-deal' : error.response.data.redirect
  router.push(route)
}

export {
  errorHandler,
  redirectHandler
}
