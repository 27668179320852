const getDefaultState = () => {
  return {
    loading: false,
    loadingFiltering: false,
    done: false,
    params: {
      page: 0,
      search: [],
      category: 0
    }
  }
}

const state = getDefaultState()

// noinspection JSUnusedGlobalSymbols
const getters = {
  paramsFiltered (state) {
    const params = Object.assign({}, state.params)

    // Remove all empty params
    Object.keys(params).forEach((key) => (!params[key] || (params[key].length === 0)) && delete params[key])

    return params
  },

  loadingFiltering (state) {
    return state.loadingFiltering
  },

  done (state) {
    return state.done
  }
}

// noinspection JSUnusedGlobalSymbols
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  showLoading (state) {
    state.loading = true
  },

  hideLoading (state) {
    state.loading = false
  },

  showLoadingFiltering (state) {
    state.loadingFiltering = true
  },

  hideLoadingFiltering (state) {
    state.loadingFiltering = false
  },

  setSearch (state, search) {
    state.params.search = search
  },

  setCategory (state, category) {
    state.params.category = category
  },

  setPage (state, page) {
    state.params.page = page
  },

  setDone (state, done) {
    state.done = done
  },

  increasePage (state) {
    state.params.page++
  },

  reset (state) {
    state.loading = false
    state.loadingFiltering = false
    state.done = false
    state.params.page = 0
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
