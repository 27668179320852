import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('idea', [
      'countStoryModeCompletedSteps',
      'countStoryModeTotalSteps'
    ]),

    completedPercentage () {
      return Number(((100 / this.countStoryModeTotalSteps) * this.countStoryModeCompletedSteps).toFixed(1))
    }
  },

  methods: {
    progressBarCustomColorMethod (percentage) {
      if (percentage < 30) {
        return '#4cda63'
      } else if (percentage < 70) {
        return '#4cda63'
      } else {
        return '#4cda63'
      }
    }
  }
}
