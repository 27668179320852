<template>
  <div class="d-flex align-items-center" :title="ideaName" @click="$emit('click')">
    <div
      v-if="imageUrl"
      class="avatar-base idea-avatar-image"
      :class="sizeClass"
      :style="{'background-image': 'url(' + imageUrl + ')'}"
    />
    <div
      v-else
      class="avatar-base avatar-default d-flex justify-content-center align-items-center"
      :class="sizeClass"
      :style="{'background-image': `url(${defaultImage})`}"
    >
      <span v-if="!idea">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { getImageBySize } from '@/helpers/imageSizeHelper'

const SIZES = ['sm', 'md', 'lg', 'xl']

export default {
  name: 'IbIdeaAvatar',

  props: {
    idea: {
      type: Object,
      default: null
    },

    size: {
      type: String,
      validator: value => {
        return SIZES.includes(value)
      },
      default: SIZES[1]
    },

    text: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },

  computed: {
    imageUrl () {
      return this.idea && this.idea.ideaSettings ? (this.idea.ideaSettings.image ? getImageBySize(this.idea.ideaSettings.image.sizes, 'small') : null) : null
    },
    ideaName () {
      return this.idea ? this.idea.name : null
    },
    defaultImage () {
      return this.idea ? (this.imageDefaultUrl(this.idea.catalogueIdea ? this.idea.catalogueIdea.cover : null) || this.imageDefaultUrl(this.idea.catalogueCategory.cover)) : null
    },
    sizeClass () {
      return `image-size-${this.size}`
    }
  },

  methods: {
    imageDefaultUrl (img) {
      if (img) {
        return `${this.baseUrl}${img.path}${img.filename}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.idea-avatar-image {
  object-fit: cover;
  border-radius: 50%;
}

.avatar-base {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 2px solid $color-text-pale-gray;
  border-radius: 50%;
  background-color: #f3f4f5;
}

.avatar-default {
  background-color: $color-white;
  color: $color-royal-blue;
  letter-spacing: 1.5px;
}

.avatar-default span {
  opacity: 0.5;
  font-size: 14px;
  line-height: 1.57;
}

.image-size-sm {
  width: 32px;
  height: 32px;
}

.image-size-md {
  width: 48px;
  height: 48px;
}

.image-size-lg {
  width: 70px;
  height: 70px;
}

.image-size-xl {
  width: 140px;
  height: 140px;
}
</style>
