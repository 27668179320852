const localeHeaderName = 'Content-Language'

const allowedLocales = ['en', 'es', 'pt', 'fr', 'de', 'it', 'pl', 'nl', 'sr']
const defaultLocale = 'en'

export {
  localeHeaderName,
  allowedLocales,
  defaultLocale
}
