import axios from 'axios'

export const actions = {
  getIdeaSettings ({ commit }) {
    return axios.get('ideas/settings')
      .then(response => response.data.payload)
      .then(response => {
        if (response.selectedIdea) {
          commit('setIdea', { idea: response.selectedIdea })
        }

        return response
      })
  },
  switchIdea ({ commit }, ideaId) {
    return axios.get(`ideas/${ideaId}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('resetState')
        commit('idea/ideaPlan/resetState', null, { root: true })
        commit('setIdea', { idea: response.idea })
      })
  },
  duplicateIdea ({ commit }, ideaId) {
    return axios.put(`ideas/duplicate/${ideaId}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('resetState')
        commit('idea/ideaPlan/resetState', null, { root: true })
        commit('setIdea', { idea: response.idea })
        commit('ideaCollection/copyIdea', response.generalInfoIdea, { root: true })
        commit('user/increaseIdeasCount', null, { root: true })
      })
  },
  leaveIdea ({ commit }, ideaId) {
    return axios.put(`ideas/leave/${ideaId}`)
      .then(_ => {
        commit('ideaCollection/removeSharedIdea', ideaId, { root: true })
      })
  },
  getBusinessGuideCompletedSteps ({ commit }) {
    return axios.get('story-mode/common/complete-step')
      .then(response => response.data.payload)
      .then(response => {
        commit('setCompletedSteps', response)
      })
  },
  getIdea ({ state, commit }) {
    return axios.get(`ideas/steps/idea/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setIdeaWhy', response.data.why)
        commit('setIdeaWhat', response.data.what)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getProduct ({ state, commit }) {
    return axios.get(`ideas/steps/product/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setProduct', response.data.products)
        commit('setUniqueSellingPropositions', response.data.uniqueSellingPropositions)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getPromotion ({ state, commit }) {
    return axios.get(`ideas/steps/promotion/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setPromotionalActivity', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getDistribution ({ state, commit }) {
    return axios.get(`ideas/steps/distribution/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setDistribution', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getPartners ({ state, commit }) {
    return axios.get(`ideas/steps/partners/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setPartners', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getMarket ({ state, commit }) {
    return axios.get(`ideas/steps/market/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMarket', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getCustomers ({ state, commit }) {
    return axios.get(`ideas/steps/customers/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setCustomers', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getCompetitors ({ state, commit }) {
    return axios.get(`ideas/steps/competitors/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setCompetitors', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getSwotAnalysis ({ state, commit }) {
    return axios.get(`ideas/steps/swot-analysis/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setSwotAnalysisStrengths', response.data.strengths)
        commit('setSwotAnalysisFromTemplate', { type: 'strengthsFromTemplate', value: response.data.strengthsFromTemplate })

        commit('setSwotAnalysisWeaknesses', response.data.weaknesses)
        commit('setSwotAnalysisFromTemplate', { type: 'weaknessesFromTemplate', value: response.data.weaknessesFromTemplate })

        commit('setSwotAnalysisOpportunities', response.data.opportunities)
        commit('setSwotAnalysisFromTemplate', { type: 'opportunitiesFromTemplate', value: response.data.opportunitiesFromTemplate })

        commit('setSwotAnalysisThreats', response.data.threats)
        commit('setSwotAnalysisFromTemplate', { type: 'threatsFromTemplate', value: response.data.threatsFromTemplate })

        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getMarketPotential ({ state, commit }) {
    return axios.get(`ideas/steps/market-potential/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getStartupCostsMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/startup-costs/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getFinancingMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/financing/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getManagementTeam ({ state, commit }) {
    return axios.get(`ideas/steps/management-team/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setManagementTeam', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getStaffAndPayroll ({ state, commit }) {
    return axios.get(`ideas/steps/staff-and-payroll/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setLaborBurden', response.data.laborBurden)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getOwnership ({ state, commit }) {
    return axios.get(`ideas/steps/ownership/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('ownership/setOwnership', response.data)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)

        return response
      })
  },
  getRevenueStreamsMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/revenue-streams/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getDirectCostsMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/direct-costs/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getMarketingBudgetMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/marketing-budget/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getOtherOverheadsMoreDetails ({ state, commit }) {
    return axios.get(`ideas/steps/other-overheads/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  fetchProfitAndCashFlow ({ state, commit }) {
    return axios.get(`ideas/steps/profit-and-cash-flow/${state.id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setDividends', response.data.dividends)
        commit('setCashCycle', response.data.cashCycle)
        commit('setIncomeTax', response.data.incomeTax)
        commit('setMoreDetailsMerge', response.notes)
        commit('setCompletedSteps', response)
      })
  },
  getIdeaScore ({ state, commit }) {
    return axios.get(`ideas/steps/idea-score/${state.id}`)
      .then(response => response.data.payload)
      .then((response) => {
        commit('setIdeaScore', response.data)

        return response
      })
  },

  deleteIdeaExamples ({ state, commit }, type) {
    return axios.delete(`ideas/delete-examples/${type}/${state.id}`)
      .then(response => response.data.payload.result)
      .then(response => {
        return {
          type,
          ids: response
        }
      })
  },
  deleteStoryModePartners ({ state, commit }, partnerId) {
    // ToDo on catch, bring back old
    commit('removePartner', partnerId)

    return axios.delete(`story-mode/concept/partner/partners/${partnerId}`)
  },
  deleteStoryModeMarketingElement ({ state, commit }, marketingElementId) {
    commit('removeMarketingElement', marketingElementId)

    return axios.delete(`story-mode/forecast/marketing/marketing-element/${marketingElementId}`)
  },
  deleteStoryModeDistribution ({ state, commit }, distributionId) {
    commit('removeDistribution', distributionId)

    return axios.delete(`story-mode/concept/distribution/distributions/${distributionId}`)
  },
  deleteStoryModePromotionalActivity ({ state, commit }, promotionalActivityId) {
    commit('removePromotionalActivity', promotionalActivityId)

    return axios.delete(`story-mode/concept/promotion/promotional-activities/${promotionalActivityId}`)
  },
  deleteStoryModeProduct ({ state, commit }, productId) {
    commit('removeProduct', productId)

    return axios.delete(`story-mode/concept/product/products/${productId}`)
  },
  deleteStoryModeStartupCosts ({ state, commit }, startupCostId) {
    commit('removeStartupCost', startupCostId)

    return axios.delete(`story-mode/set-up/startup-costs/startup-costs/${startupCostId}`)
  },
  deleteStoryModeStaffAndPayroll ({ state, commit }, staffAndPayrollId) {
    commit('removeStaffAndPayroll', staffAndPayrollId)

    return axios.delete(`story-mode/set-up/staff-and-payroll/staff-and-payroll/${staffAndPayrollId}`)
  },
  deleteStoryModeCustomers ({ state, commit }, customerId) {
    commit('removeCustomer', customerId)

    return axios.delete(`story-mode/research/customers/customers/${customerId}`)
  },
  deleteStoryModeCompetitors ({ state, commit }, competitorId) {
    commit('removeCompetitor', competitorId)

    return axios.delete(`story-mode/research/competitors/competitors/${competitorId}`)
  },
  deleteStoryModeTeamMember ({ state, commit }, memberId) {
    commit('removeTeamMember', memberId)

    return axios.delete(`story-mode/set-up/team/members/${memberId}`)
  },
  deleteStoryModeRevenueStream ({ state, commit }, revenueStreamId) {
    commit('removeRevenueStream', revenueStreamId)

    return axios.delete(`story-mode/forecast/revenue-stream/revenue-stream/${revenueStreamId}`)
  },
  deleteStoryModeDirectCost ({ state, commit }, directCostId) {
    commit('removeDirectCost', directCostId)

    return axios.delete(`story-mode/forecast/direct-costs/direct-costs/${directCostId}`)
  },
  deleteStoryModeDividend ({ state, commit }, dividendId) {
    commit('removeDividend', dividendId)

    return axios.delete(`story-mode/forecast/profit-and-cash-flow/dividends/${dividendId}`)
  },
  deleteStoryModeOtherOverhead ({ state, commit }, otherOverheadId) {
    commit('removeOtherOverhead', otherOverheadId)

    return axios.delete(`story-mode/forecast/other-overhead/other-overhead/${otherOverheadId}`)
  },
  deleteStoryModeFinance ({ state, commit }, financeId) {
    commit('removeFinance', financeId)

    return axios.delete(`story-mode/set-up/financing/finances/${financeId}`)
  },
  deleteJournalSection ({ state, commit }, journalSectionId) {
    commit('removeJournalSection', journalSectionId)

    return axios.delete(`story-mode/journal/sidebar/${journalSectionId}`)
  },
  uploadImage ({ state }, { formData, type }) {
    return axios.post(`upload/image/${type}`, formData)
      .then(response => response.data.payload)
      .then(response => {
        return response
      })
  },
  updateOrderNumber ({ state, commit }, { type, data, oldOrderData, mutation, additional }) {
    const reorderData = data.map((item, index) => {
      const orderNumber = index + 1

      return {
        id: item.id,
        orderNumber
      }
    })

    if (type === 'idea') {
      commit(mutation, { ideas: data }, { root: true })
    } else if (type === 'ideaPlan') {
      commit(mutation, { sectionId: additional, data })
    } else {
      commit(mutation, data)
    }

    return axios.post(`ideas/update-order-number/${type}/${state.id}`, { data: reorderData, additional })
      .catch(() => {
        if (type === 'idea') {
          commit(mutation, { ideas: oldOrderData }, { root: true })
        } else {
          commit(mutation, oldOrderData)
        }
      })
  },
  completeStep ({ state, commit }, payload) {
    return axios.put('story-mode/common/complete-step', payload)
      .then(response => response.data.payload)
      .then(response => {
        commit('setJournalSwitchSidebarElements', response.journalSwitchBars)

        return response
      })
  },

  updateBusinessPlanAdditionalInfo ({ commit }, { id, content }) {
    return axios.put(`story-mode/journal/subtitle/${id}`, { content })
      .then(response => response.data.payload)
      .then(response => {
        commit('setJournalSwitchSidebarElements', response.journalSwitchBars)

        return response
      })
  }
}
