const state = () => ({
  visibleImageUnlock: false
})

const mutations = {
  toggleImageUnlock (state, status) {
    state.visibleImageUnlock = status
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
