import axios from 'axios'

const getDefaultState = () => {
  return {
    id: 0,
    roomId: null
  }
}

const state = getDefaultState()

const actions = {
  getWhiteboard ({ commit }, ideaId) {
    return axios.get(`whiteboard/${ideaId}`)
      .then(response => {
        return response
      })
  }
}

const getters = {
  ideaWhiteboard: (state) => {
    return state
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
