import UserRoleEnum from '@/constants/UserRoleEnum'

const getDefaultState = () => {
  return {
    sidebar: false,
    accountSettings: false,
    dialogs: {
      aiAssistance: false,
      ideaSettings: false,
      userInvite: {
        visible: false,
        role: null
      },
      upgrade: false,
      paymentHistory: false,
      logout: false,
      feedback: false,
      changeBilling: false
    }
  }
}

const state = getDefaultState()

const getters = {
  getSidebar (state) {
    return state.sidebar
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setSidebarToggle (state) {
    state.sidebar = !state.sidebar
  },
  setSidebarState (state, data) {
    state.sidebar = data
  },
  setAccountSettingsToggle (state) {
    state.accountSettings = !state.sidebar
  },
  setAccountSettingsState (state, data) {
    state.accountSettings = data
  },

  setDialogAiAssistanceState (state, data) {
    state.dialogs.aiAssistance = data
  },

  setDialogUpgradeState (state, data) {
    state.dialogs.upgrade = data
  },
  setDialogIdeaSettingsState (state, data) {
    state.dialogs.ideaSettings = data
  },
  setDialogUserInviteState (state, { role = UserRoleEnum.GUEST, visible }) {
    state.dialogs.userInvite.role = role
    state.dialogs.userInvite.visible = visible
  },
  setDialogUserInviteClosed (state) {
    state.dialogs.userInvite.role = null
    state.dialogs.userInvite.visible = false
  },
  setDialogPaymentHistoryState (state, data) {
    state.dialogs.paymentHistory = data
  },
  setDialogLogoutState (state, data) {
    state.dialogs.logout = data
  },
  setDialogFeedbackState (state, data) {
    state.dialogs.feedback = data
  },
  setDialogChangeBillingState (state, data) {
    state.dialogs.changeBilling = data
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
