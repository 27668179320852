import App from './App.vue'
import Breakpoints from '@/helpers/breakpointHelper'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VueAuthenticate from 'vue-authenticate'
import VueClipboard from 'vue-clipboard2'
import VueCurrencyInput from 'vue-currency-input'
import VueGtm from 'vue-gtm'
import VueIntercom from 'vue-intercom'
import VueSimpleSVG from 'vue-simple-svg'
import VueTypedJs from 'vue-typed-js'
import axios from 'axios'
import i18n from './i18n'
import isTouchDevice from 'is-touch-device'
import locale from 'element-ui/lib/locale/lang/en'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'

import '@/plugins/vue-awesome-countdown'
import '@/plugins/moment'
import '@/plugins/vee-validate'
import 'element-ui/packages/theme-chalk/src/index.scss'
import * as Sentry from '@sentry/vue'

import '@/assets/scss/main.scss'
import '@/components'
import { errorHandler, redirectHandler } from '@/helpers/errorHandler'
import { BrowserTracing } from '@sentry/tracing'
import { SocialAuthOptions } from '@/options/socialAuthOptions'
import { intercomPaymentUserBoot } from '@/helpers/intercomHelpers'
import { localize } from 'vee-validate'
import { removedSelectedIdeaError } from '@/helpers/errorMessageHelpers'
// import VueGtag from 'vue-gtag'
import { allowedLocales, defaultLocale, localeHeaderName } from '@/options/localeOptions'
import { parseLanguageFromStartLocation } from '@/helpers/queryLanguageHelper'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['ideabuddy.test', 'ideabuddy.com', process.env.VUE_APP_BASE_URL, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

const currentLang = parseLanguageFromStartLocation(router.history._startLocation) || localStorage.getItem('lang')

if (!currentLang || currentLang === 'undefined' || !allowedLocales.includes(currentLang)) {
  localStorage.setItem('lang', defaultLocale)
  i18n.locale = defaultLocale
  vuetify.lang = defaultLocale
  localize(defaultLocale)
} else {
  localStorage.setItem('lang', currentLang)
  i18n.locale = currentLang
  vuetify.lang = currentLang
  localize(currentLang)
}

axios.defaults.headers.common[localeHeaderName] = localStorage.getItem('lang') && allowedLocales.includes(localStorage.getItem('lang'))
  ? localStorage.getItem('lang')
  : defaultLocale

axios.interceptors.response.use(response => {
  if (response.headers['x-api-build'] && store.getters['app/getApiBuild'] !== parseInt(response.headers['x-api-build'])) {
    store.commit('app/setApiBuild', response.headers['x-api-build'])
  }

  if (response.status === 200 && response.data.payload && response.data.payload.removedSelectedIdea) {
    Vue.prototype.$notify(removedSelectedIdeaError)
    router.push({ name: 'idea-collection' })
  }

  if (response.status === 200 && response.data.payload && response.data.payload.newSharedIdea) {
    Vue.prototype.$notify({
      title: 'New shared idea',
      dangerouslyUseHTMLString: true,
      message: 'A new idea is shared with you! <br>Go to your idea collection.',
      type: 'info'
    })
  }

  return response
}, error => {
  if (error.response && error.response.data && error.response.data.user) {
    store.commit('user/setUser', error.response.data)
  }

  if (error.response && error.response.data) {
    errorHandler(error)
  }

  if (error.response && error.response.data && error.response.data.redirect) {
    redirectHandler(error)
  }

  return Promise.reject(error)
})

// API base url
axios.defaults.baseURL = `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_VERSION}`
axios.defaults.headers.common['X-Authorization'] = `Bearer ${Cookies.get('access_token')}`

// noinspection JSUnusedGlobalSymbols
Vue.prototype.$http = axios
Vue.prototype.$isTouchDevice = isTouchDevice()

Vue.use(Breakpoints, {
  store: new Breakpoints.Store()
})
Vue.use(ElementUI, { locale })
Vue.use(require('vue-cookies'))
Vue.use(require('detect-autofill'))

Vue.use(VueSimpleSVG)
Vue.use(VueAuthenticate, SocialAuthOptions)
Vue.use(VueClipboard)
Vue.use(Vue2TouchEvents)
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'USD' }
})
Vue.use(VueTypedJs)

// Vue.use(VueGtag, {
//   config: {
//     id: 'AW-692275013'
//   }
// })

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router
})

Vue.use(VueIntercom, {
  appId: process.env.VUE_APP_INTERCOM_ID
})

// noinspection JSUnusedGlobalSymbols
const vue = new Vue({
  i18n,
  router,
  store,
  vuetify,
  watch: {
    $route: function () {
      window.Intercom('update')
    }
  },
  render: h => h(App)
}).$mount('#app')

if (Cookies.get('access_token')) {
  store.dispatch('user/refreshUserData')
    .then(response => {
      const user = response.data.payload.user
      intercomPaymentUserBoot(user, vue.$intercom)

      // Hide intercom launcher on whiteboard
      if (vue.$router.currentRoute.name === 'whiteboard') {
        vue.$intercom.update({ hide_default_launcher: true })
      }
    })
}
