import Router from 'vue-router'
import Vue from 'vue'
import i18n from '@/i18n'
import store from './store'

import onboardingRoutes from '@/views/Onboarding/routes'

import { scrollToTop } from '@/helpers/scrollHelpers'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      redirect: (store.getters['user/ideasCount'] === 0 && store.getters['ideaCollection/getSharedIdeasCount'] === 0 && !store.getters['user/isInvited']) ? 'select-idea' : !store.getters['idea/isIdeaSelected'] ? 'idea-collection' : null,
      component: () => import(/* webpackChunkName: "home" */ './views/Home/Home').then(value => value.default),
      children: [
        {
          path: '',
          name: 'home',
          meta: {
            title: 'Idea Home',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "idea-home" */ './views/Home/IdeaHome/IdeaHomePage.vue').then(value => value.default)
        },
        {
          path: '/admin',
          name: 'admin',
          meta: {
            title: 'Admin',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "admin-home" */ './views/Admin/AdminHome').then(value => value.default)
        },
        {
          path: '/ai-assistance',
          name: 'ai-assistance',
          meta: {
            title: 'Ai Assistance',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "ai-assistance" */ './views/Home/AiAssistance/AiAssistancePage.vue').then(value => value.default)
        },
        {
          path: '/account-settings/my-profile',
          name: 'account-settings-my-profile',
          meta: {
            title: 'My Profile',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "account-settings-my-profile" */ './views/Home/AccountSettings/MyProfile/MyProfile').then(value => value.default)
        },
        {
          path: '/idea-collection',
          name: 'idea-collection',
          meta: {
            title: 'Idea Collection',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "account-settings-idea-collection" */ './views/Home/IdeaCollection/IdeaCollection').then(value => value.default)
        },
        {
          path: '/users',
          name: 'users',
          meta: {
            title: 'Users',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "invited-users" */ './views/Home/InvitedUsers/InvitedUsers').then(value => value.default)
        },
        {
          path: '/story-mode',
          redirect: { name: 'business-guide' }
        },
        {
          path: '/business-guide',
          name: 'business-guide',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide" */ './views/Home/StoryMode/BusinessGuide').then(value => value.default)
        },
        {
          path: '/business-guide/concept/idea',
          name: 'business-guide-concept-idea',
          alias: '/story-mode/concept/idea',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.idea',
            completedStepName: 'ideaCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-concept-idea" */ './views/Home/StoryMode/Concept/Idea/Idea').then(value => value.default)
        },
        {
          path: '/business-guide/concept/product',
          name: 'business-guide-concept-product',
          alias: '/story-mode/concept/product',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.product',
            completedStepName: 'productCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-concept-product" */ './views/Home/StoryMode/Concept/Product/Product').then(value => value.default)
        },
        {
          path: '/business-guide/concept/promotion',
          name: 'business-guide-concept-promotion',
          alias: '/story-mode/concept/promotion',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.promotion',
            completedStepName: 'promotionCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-concept-promotion" */ './views/Home/StoryMode/Concept/Promotion/Promotion').then(value => value.default)
        },
        {
          path: '/business-guide/concept/distribution',
          name: 'business-guide-concept-distribution',
          alias: '/story-mode/concept/distribution',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.distribution',
            completedStepName: 'distributionCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-concept-distribution" */ './views/Home/StoryMode/Concept/Distribution/Distribution').then(value => value.default)
        },
        {
          path: '/business-guide/concept/partners',
          name: 'business-guide-concept-partners',
          alias: '/story-mode/concept/partners',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.partners',
            completedStepName: 'partnerCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-concept-partners" */ './views/Home/StoryMode/Concept/Partner/Partner').then(value => value.default)
        },
        {
          path: '/business-guide/research/market',
          name: 'business-guide-research-market',
          alias: '/story-mode/research/market',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.market',
            completedStepName: 'marketCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-research-market" */ './views/Home/StoryMode/Research/Market/Market').then(value => value.default)
        },
        {
          path: '/business-guide/research/market-potential',
          name: 'business-guide-research-market-potential',
          alias: '/story-mode/research/market-potential',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.marketPotential',
            completedStepName: 'marketPotentialCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-research-market-potential" */ './views/Home/StoryMode/Research/MarketPotential/MarketPotential').then(value => value.default)
        },
        {
          path: '/business-guide/research/customers',
          name: 'business-guide-research-customers',
          alias: '/story-mode/research/customers',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.customer',
            completedStepName: 'customersCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-research-customers" */ './views/Home/StoryMode/Research/Customers/Customers').then(value => value.default)
        },
        {
          path: '/business-guide/research/competitors',
          name: 'business-guide-research-competitors',
          alias: '/story-mode/research/competitors',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.competitors',
            completedStepName: 'competitorsCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-research-competitors" */ './views/Home/StoryMode/Research/Competitors/Competitors').then(value => value.default)
        },
        {
          path: '/business-guide/research/swot-analysis',
          name: 'business-guide-research-swot-analysis',
          alias: '/story-mode/research/swot-analysis',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.swotAnalysis',
            completedStepName: 'swotAnalysisCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-research-competitors" */ './views/Home/StoryMode/Research/SwotAnalysis/SwotAnalysis').then(value => value.default)
        },
        {
          path: '/business-guide/set-up/startup-costs',
          name: 'business-guide-set-up-startup-costs',
          alias: '/story-mode/set-up/startup-costs',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.startupCosts',
            completedStepName: 'startupCostsCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-set-up-startup-costs" */ './views/Home/StoryMode/SetUp/StartupCosts/StartupCosts').then(value => value.default)
        },
        {
          path: '/business-guide/set-up/financing',
          name: 'business-guide-set-up-financing',
          alias: '/story-mode/set-up/financing',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.financing',
            completedStepName: 'financingCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-set-up-financing" */ './views/Home/StoryMode/SetUp/Financing/Financing').then(value => value.default)
        },
        {
          path: '/business-guide/set-up/management-team',
          name: 'business-guide-set-up-management-team',
          alias: '/story-mode/set-up/management-team',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.managementTeam',
            completedStepName: 'managementTeamCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-set-up-management-team" */ './views/Home/StoryMode/SetUp/ManagementTeam/ManagementTeam').then(value => value.default)
        },
        {
          path: '/business-guide/set-up/staff-and-payroll',
          name: 'business-guide-set-up-staff-and-payroll',
          alias: '/story-mode/set-up/staff-and-payroll',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.staffAndPayroll',
            completedStepName: 'employeesAndPayrollCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-set-up-staff-and-payroll" */ './views/Home/StoryMode/SetUp/StaffAndPayroll/StaffAndPayroll').then(value => value.default)
        },
        {
          path: '/business-guide/set-up/ownership',
          name: 'business-guide-set-up-ownership',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.ownership',
            completedStepName: 'ownershipCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-ownership" */ './views/Home/StoryMode/SetUp/Ownership/Ownership').then(value => value.default)
        },
        {
          path: '/business-guide/projections/revenue-streams',
          name: 'business-guide-projections-revenue-streams',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.revenueStreams',
            completedStepName: 'revenueStreamsCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-projections-revenue-streams" */ './views/Home/StoryMode/Forecast/RevenueStreams/RevenueStreams').then(value => value.default)
        },
        {
          path: '/business-guide/projections/direct-costs',
          name: 'business-guide-projections-direct-costs',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.directCosts',
            completedStepName: 'directCostsCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-projections-direct-costs" */ './views/Home/StoryMode/Forecast/DirectCosts/DirectCosts').then(value => value.default)
        },
        {
          path: '/business-guide/projections/marketing-budget',
          name: 'business-guide-projections-marketing-budget',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.marketingBudget',
            completedStepName: 'marketingBudgetCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-projections-marketing-budget" */ './views/Home/StoryMode/Forecast/MarketingBudget/MarketingBudget').then(value => value.default)
        },
        {
          path: '/business-guide/projections/other-overheads',
          name: 'business-guide-projections-other-overheads',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.otherOverheads',
            completedStepName: 'otherOverheadsCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-projections-other-overheads" */ './views/Home/StoryMode/Forecast/OtherOverheads/OtherOverheads').then(value => value.default)
        },
        {
          path: '/business-guide/projections/profit-and-cash-flow',
          name: 'business-guide-projections-profit-and-cash-flow',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.profitAndCashFlow',
            completedStepName: 'profitAndCashFlowCompleted',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-guide-projections-profit-and-cash-flow" */ './views/Home/StoryMode/Forecast/ProfitAndCashFlow/ProfitAndCashFlow').then(value => value.default)
        },
        {
          path: '/validation/idea-score',
          name: 'validation-idea-score',
          alias: '/story-mode/validation/idea-score',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.ideaScore',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "validation-idea-score" */ './views/Home/StoryMode/Validation/IdeaScore/IdeaScore').then(value => value.default)
        },
        {
          path: '/validation/idea-score/test',
          name: 'validation-idea-score-test',
          meta: {
            title: i18n.t('sidebar.businessGuide'),
            subtitle: 'pages.businessGuide.navigation.ideaValidation',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "validation-idea-score-test" */ './views/Home/StoryMode/Validation/IdeaScore/IdeaScoreTest').then(value => value.default)
        },
        {
          path: '/story-mode/overview',
          redirect: { name: 'financial-plan' }
        },
        {
          path: '/financial-plan',
          redirect: { name: 'financial-plan-overview' },
          name: 'financial-plan',
          meta: {
            title: i18n.t('sidebar.financialPlan'),
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "financial-plan" */ './views/Home/StoryMode/FinancialPlan/FinancialPlan').then(value => value.default),
          children: [
            {
              path: 'overview',
              name: 'financial-plan-overview',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.overview'
              },
              component: () => import(/* webpackChunkName: "financial-plan-overview" */ './views/Home/StoryMode/FinancialPlan/Pages/Overview').then(value => value.default)
            },
            {
              path: 'revenue',
              name: 'financial-plan-revenue',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.revenue'
              },
              component: () => import(/* webpackChunkName: "financial-plan-revenue" */ './views/Home/StoryMode/FinancialPlan/Pages/Revenue').then(value => value.default)
            },
            {
              path: 'expenses/startup-costs',
              name: 'financial-plan-expenses-startup-costs',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.startupCosts'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-startup-costs" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesStartupCosts').then(value => value.default)
            },
            {
              path: 'expenses/payroll-expenses',
              name: 'financial-plan-expenses-payroll-expenses',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.payrollExpenses'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-payroll-expenses" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesStaffAndPayroll').then(value => value.default)
            },
            {
              path: 'expenses/direct-costs',
              name: 'financial-plan-expenses-direct-costs',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.directCosts'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-direct-costs" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesDirectCosts').then(value => value.default)
            },
            {
              path: 'expenses/marketing-costs',
              name: 'financial-plan-expenses-marketing-costs',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.marketingCosts'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-marketing-costs" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesMarketingCosts').then(value => value.default)
            },
            {
              path: 'expenses/other-overheads',
              name: 'financial-plan-expenses-other-overheads',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.otherOverheads'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-other-overheads" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesOtherOverheads').then(value => value.default)
            },
            {
              path: 'expenses/total-costs',
              name: 'financial-plan-expenses-total-costs',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.totalCosts'
              },
              component: () => import(/* webpackChunkName: "financial-plan-expenses-total-costs" */ './views/Home/StoryMode/FinancialPlan/Pages/ExpensesTotalCosts').then(value => value.default)
            },
            {
              path: 'financing',
              name: 'financial-plan-financing',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.financing'
              },
              component: () => import(/* webpackChunkName: "financial-plan-financing" */ './views/Home/StoryMode/FinancialPlan/Pages/Financing').then(value => value.default)
            },
            {
              path: 'profit-and-cash-flow',
              name: 'financial-plan-profit-and-cash-flow',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.navigation.profitAndCashFlow'
              },
              component: () => import(/* webpackChunkName: "financial-plan-profit-and-cash-flow" */ './views/Home/StoryMode/FinancialPlan/Pages/ProfitAndCashFlow').then(value => value.default)
            },
            {
              path: 'settings',
              name: 'financial-plan-settings',
              meta: {
                title: i18n.t('sidebar.financialPlan'),
                subtitle: 'pages.financialPlan.financialSettings'
              },
              component: () => import(/* webpackChunkName: "financial-plan-profit-and-cash-flow" */ './views/Home/StoryMode/FinancialPlan/Pages/Settings').then(value => value.default)
            }
          ]
        },
        {
          path: '/story-mode/journal',
          redirect: { name: 'business-plan' }
        },
        {
          path: '/business-plan',
          name: 'business-plan',
          meta: {
            title: i18n.t('sidebar.businessPlan'),
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "business-plan" */ './views/Home/StoryMode/Journal/BusinessPlan').then(value => value.default)
        },
        {
          path: '/whiteboard',
          name: 'whiteboard',
          meta: {
            title: i18n.t('sidebar.whiteboard'),
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "whiteboard" */ './views/Home/Whiteboard/Whiteboard').then(value => value.default)
        },
        {
          path: '/idea-plan',
          name: 'idea-plan',
          meta: {
            title: 'Idea Plan',
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "idea-plan" */ './views/Home/IdeaPlan/IdeaPlan').then(value => value.default)
        },
        {
          path: '/idea-settings',
          name: 'idea-settings',
          meta: {
            title: 'Idea Settings'
          },
          component: () => import(/* webpackChunkName: "idea-settings" */ './views/Home/IdeaSettings/IdeaSettingsPage.vue').then(value => value.default)
        }
      ]
    },
    {
      path: '/verification',
      name: 'verification',
      meta: {
        title: 'Verification',
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "verification" */ './views/Signup/Signup').then(value => value.default)
    },
    {
      path: '/idea-plan/:token',
      name: 'idea-plan-shared',
      meta: {
        title: 'Idea Plan'
        // guest: true
      },
      component: () => import(/* webpackChunkName: "shared-journal" */ './views/Home/IdeaPlan/IdeaPlanShared').then(value => value.default)
    },
    {
      path: '/story-mode/journal/:token',
      redirect: '/business-plan/:token'
    },
    {
      path: '/business-plan/:token',
      name: 'business-plan-shared',
      meta: {
        title: i18n.t('sidebar.businessPlan')
        // guest: true
      },
      component: () => import(/* webpackChunkName: "business-plan-shared" */ './views/Home/StoryMode/Journal/BusinessPlanShared').then(value => value.default)
    },
    {
      path: '/story-mode/overview/:token',
      redirect: '/financial-plan/:token'
    },
    {
      path: '/financial-plan/:token',
      name: 'financial-plan-shared',
      meta: {
        title: 'Financial Plan Shared'
        // guest: true
      },
      component: () => import(/* webpackChunkName: "financial-plan-shared" */ './views/Home/StoryMode/FinancialPlan/FinancialPlanShared').then(value => value.default)
    },
    ...onboardingRoutes,
    {
      path: '/select-idea-old',
      name: 'select-idea-old',
      meta: {
        title: 'Select Idea',
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "select-idea" */ './views/SelectIdea/SelectIdea').then(value => value.default)
    },
    {
      path: '/product-tour',
      name: 'product-tour',
      meta: {
        title: 'Product Tour',
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "product-tour" */ './views/SelectIdea/ProductTour').then(value => value.default)
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'Log In',
        guest: true
      },
      component: () => import(/* webpackChunkName: "login" */ './views/Login/Login').then(value => value.default)
    },
    {
      path: '/signup',
      name: 'signup',
      meta: {
        title: 'Sign Up',
        guest: true
      },
      component: () => import(/* webpackChunkName: "signup" */ './views/Signup/Signup').then(value => value.default)
    },
    {
      path: '/invitation',
      name: 'invitation',
      meta: {
        title: 'Invitation',
        guest: true
      },
      component: () => import(/* webpackChunkName: "signup" */ './views/Invitation/Invitation').then(value => value.default)
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: {
        title: 'Reset Password',
        guest: true
      },
      component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword/ResetPassword').then(value => value.default)
    },
    {
      path: '/payment',
      name: 'payment',
      meta: {
        title: 'Payment'
      },
      component: () => import(/* webpackChunkName: "payment-signup" */ './views/Payment/Payment').then(value => value.default)
    },
    {
      path: '/payment/login',
      name: 'payment-login',
      meta: {
        title: 'Payment login',
        guest: true
      },
      component: () => import(/* webpackChunkName: "payment-login" */ './views/Payment/Payment').then(value => value.default)
    },
    {
      path: '/payment/lifetime-deal',
      name: 'payment-lifetime-deal',
      meta: {
        title: 'Lifetime Deal'
      },
      component: () => import(/* webpackChunkName: "payment-lifetime-deal" */ './views/Payment/Payment').then(value => value.default)
    },
    {
      path: '/payment/lifetime-deal/login',
      name: 'payment-lifetime-deal-login',
      meta: {
        title: 'Lifetime Deal login',
        guest: true
      },
      component: () => import(/* webpackChunkName: "payment-lifetime-deal-login" */ './views/Payment/Payment').then(value => value.default)
    },
    {
      path: '/appsumo/:code',
      name: 'app-sumo-signup',
      meta: {
        title: 'AppSumo Signup'
      },
      component: () => import(/* webpackChunkName: "app-sumo-signup" */ './views/AppSumo/Signup').then(value => value.default)
    },
    {
      path: '/dealify',
      name: 'dealify-signup',
      meta: {
        title: 'Dealify Signup'
      },
      component: () => import(/* webpackChunkName: "dealify-signup" */ './views/Dealify/Signup').then(value => value.default)
    },
    {
      path: '*',
      name: '404',
      meta: {
        title: 'Oops... page not found'
      },
      component: () => import(/* webpackChunkName: "404" */ './views/404')
    }
  ]
})

const titleSuffix = ' | IdeaBuddy'

router.afterEach((to, from, next) => {
  // Scroll to the top of the page
  scrollToTop()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth === true)) {
    if (!store.getters['user/isLoggedIn'] && (from.name !== 'login' && from.name !== 'signup')) {
      store.commit('app/setNextUrl', to.name)
      next({ name: 'login', params: { nextUrl: to.fullPath } })

      return
    }

    if (to.name !== 'verification' && store.getters['user/isVerified'] === false) {
      next({ name: 'verification', query: to.query })

      return
    }

    if (store.getters['user/isVerified'] !== false) {
      if (!store.getters['user/isSubscribed'] && store.getters['ideaCollection/getSharedIdeasCount'] === 0) {
        next({ name: 'payment' })

        return
      }

      if (to.name === 'select-idea' && !store.getters['user/isSubscribed']) {
        next({ name: 'payment' })

        return
      }

      if (to.name !== 'select-idea' && !store.getters['user/userInitials']) {
        next({ name: 'select-idea' })

        return
      }

      if (to.name !== 'select-idea' && store.getters['user/ideasCount'] === 0 && !store.getters['user/isInvited']) {
        next({ name: 'select-idea' })

        return
      }

      if (!store.getters['idea/isIdeaSelected'] && !['account-settings-my-profile', 'select-idea', 'idea-collection'].includes(to.name)) {
        next({ name: 'idea-collection' })

        return
      }
    }

    const nextUrl = store.getters['app/getNextUrl']
    if (nextUrl && !from.query.template) {
      store.dispatch('app/resetNextUrl').then(r => next({ name: nextUrl }))

      return
    }

    const subtitle = to.meta.subtitle ? ` - ${i18n.t(to.meta.subtitle)}` : ''
    document.title = to.meta.title + subtitle + titleSuffix
    store.commit('app/setNextUrl', null)
    next()
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.getters['user/isLoggedIn'] === false) {
      document.title = to.meta.title + titleSuffix
      next()

      return
    }

    // Choose template from site
    if (to.name === 'signup' && to.query.template) {
      next({ name: 'select-idea', query: to.query })

      return
    }

    next('/')
  } else {
    document.title = to.meta.title + titleSuffix
    next()
  }
})

export default router
