const getDefaultState = () => {
  return {
    nextUrl: null,
    apiBuild: null,
    clientBuild: null
  }
}

const state = getDefaultState()

const getters = {
  getNextUrl (state) {
    return state.nextUrl
  },
  getApiBuild (state) {
    return state.apiBuild
  },
  getClientBuild (state) {
    return state.clientBuild
  },
  isClientUpdated (state) {
    if (state.apiBuild && state.clientBuild) {
      return (state.apiBuild - 60) > state.clientBuild
    }

    return false
  }
}

const actions = {
  resetNextUrl ({ commit }) {
    commit('setNextUrl', null)
  },
  resetApiBuild ({ commit }) {
    commit('setApiBuild', null)
  },
  resetClientBuild ({ commit }) {
    commit('setClientBuild', null)
  }
}

const mutations = {
  setNextUrl (state, url) {
    state.nextUrl = url
  },

  setApiBuild (state, timestamp) {
    state.apiBuild = parseInt(timestamp)
  },

  setClientBuild (state, timestamp) {
    state.clientBuild = timestamp
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
