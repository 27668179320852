import axios from 'axios'

const actions = {
  appSumoAuth ({ commit }, data) {
    return axios.post('authentication/signup/promo/app-sumo', data)
      .then(response => {
        return response
      })
  },
  dealifyAuth ({ commit }, data) {
    return axios.post('authentication/signup/promo/dealify', data)
      .then(response => {
        return response
      })
  }
}

export default {
  actions,
  namespaced: true
}
