const getDefaultState = () => {
  return {
    data: [
      {
        image: require('@/assets/img/illustrations/benefits/collaborate.png'),
        title: 'pages.pricing.benefitsCards.card01.head',
        text: 'pages.pricing.benefitsCards.card01.text'
      },
      {
        image: require('@/assets/img/illustrations/benefits/export.png'),
        title: 'pages.pricing.benefitsCards.card02.head',
        text: 'pages.pricing.benefitsCards.card02.text'
      },
      {
        image: require('@/assets/img/illustrations/benefits/ideas.png'),
        title: 'pages.pricing.benefitsCards.card03.head',
        text: 'pages.pricing.benefitsCards.card03.text'
      }
    ]
  }
}

const state = getDefaultState()

const getters = {
  getBenefitsData (state) {
    return state.data
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
