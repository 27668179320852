import Cookies from 'js-cookie'
import UserPlanEnum from '@/constants/UserPlanEnum'
import UserStatusEnum from '@/constants/UserStatusEnum'

export const getters = {
  getCurrentUser: (state) => {
    return state
  },

  isLoggedIn: function (state) {
    return !!state.email && !!Cookies.get('access_token')
  },

  isVerified: (state) => {
    return state.isVerified
  },

  isAppSumoUser: function (state) {
    return state.isAppSumoUser
  },

  isLifetimeSubscription: (state) => {
    return state.isLifetimeSubscription
  },

  userId: function (state) {
    return state.id
  },

  userImage: function (state) {
    return state.image
  },

  userGender: function (state) {
    return state.gender
  },

  userEmail: function (state) {
    return state.email
  },

  getUserFirstName: (state) => {
    return state.firstName
  },

  userFullName: (state) => {
    const { firstName, lastName } = state

    return `${firstName} ${lastName}`
  },

  userInitials: function (state) {
    const { firstName, lastName } = state

    return firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : null
  },

  ideasCount: function (state) {
    return state.ideasCount
  },

  ideasInPlan: function (state) {
    return state.subscription.ideas
  },

  usersInPlan: function (state) {
    return state.subscription.users
  },

  images: function (state) {
    return state.images
  },

  isSubscribed: function (state) {
    return state.subscribed
  },

  subscriptionPlanName: function (state) {
    return state.subscription.plan.replace(/([A-Z])/g, ' $1')
  },

  isFreeTrial: function (state) {
    return !(state.freeTrial === null) && state.subscription.plan === UserPlanEnum.DREAMER
  },

  isDreamer: function (state) {
    return state.freeTrial === null && state.subscription.plan === UserPlanEnum.DREAMER
  },

  isFounder: function (state) {
    return state.subscription.plan === UserPlanEnum.FOUNDER
  },

  isTeamPro: function (state) {
    return state.subscription.plan === UserPlanEnum.TEAM_PRO
  },

  isDisabled: function (state) {
    return state.status === UserStatusEnum.DISABLED
  },

  isTemplateAdmin: function (state) {
    return !!state.templateAdmin
  },

  isInvited: function (state) {
    return state.invited
  },

  isUsersLimitExceeded: function (state) {
    return state.collaborators.items.length >= state.subscription.users
  },

  getUserCountry: (state) => {
    return state.country
  },

  isQuickTourCompleted: (state) => (quickTourName) => {
    return state.quickTours[quickTourName]
  },

  isAiRequestsLimitExceeded: (state) => {
    return state.aiRequests.requests === state.aiRequests.spent
  },

  isAiAdditionalRequestsLimitExceeded: (state) => {
    return state.aiRequests.additionalRequests === state.aiRequests.additionalRequestsSpent
  },

  getAiRequests: (state) => {
    return state.aiRequests
  },

  getIsFirstTime: (state) => {
    return state.firstTime
  }
}
