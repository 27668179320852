<template>
  <div class="template-accordion-wrapper">
    <p class="cursor-pointer" @click="show = !show">
      {{ title }}
      <i
        class="arrow-icon el-icon-arrow-down"
        :class="{'rotate': show}"
      />
    </p>
    <Transition name="slide">
      <div v-show="show">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'IbSingleAccordion',

  props: {
    title: {
      type: String,
      required: true
    },

    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-accordion-wrapper {
  p {
    font-size: 24px;
    margin: 0 0 28px;
  }

  .arrow-icon {
    transition: transform .3s;

    &.rotate {
      transform: rotate(-180deg);
    }
  }
}
</style>
