<template>
  <div class="d-flex justify-content-center my-5">
    <img alt="Loader" src="@/assets/img/util/loader.gif" width="250">
  </div>
</template>

<script>
export default {
  name: 'IbLoader'
}
</script>
