import Vue from 'vue'

const scrollToTop = function (elementSelector = '.page-content') {
  const element = document.querySelector(elementSelector)

  if (element) {
    element.scrollTo(0, 0)
    element.scrollIntoView()
  }
}

const scrollToBottom = function (elementSelector = '.page-content') {
  const element = document.querySelector(elementSelector)

  if (element && !Vue.prototype.$isTouchDevice) {
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    })

    return
  }

  const scrollingElement = (document.scrollingElement || document.body)
  scrollingElement.scrollTo({
    top: scrollingElement.scrollHeight,
    behavior: 'smooth'
  })
}

export {
  scrollToTop,
  scrollToBottom
}
