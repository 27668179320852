<template>
  <div class="ib-checkmark position-relative">
    <i class="el-icon-check position-absolute" />
  </div>
</template>

<script>
export default {
  name: 'IbCheckmark'
}
</script>

<style scoped lang="scss">
.ib-checkmark {
  width: 35px;
  height: 35px;
  background-color: $color-green;
  border-radius: 50%;

  i {
    top: 22%;
    right: 22%;
    color: $color-white;
    font-size: 20px;
  }
}
</style>
