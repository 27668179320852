<template>
  <component
    :is="component"
    :to="to"
    class="ib-card-wrapper bg-white position-relative"
    :class="{'ib-card-wrapper-hover': animation, 'ib-card-wrapper-transition': animation, 'decoration-none': isRoutable}"
    @click="onClick"
  >
    <button
      v-if="closable"
      type="button"
      aria-label="Close"
      class="el-dialog__headerbtn ib-card-close-button"
      @click="$emit('close')"
    >
      <i class="el-dialog__close el-icon el-icon-close" />
    </button>
    <slot />
  </component>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'IbCard',

  props: {
    to: {
      type: Object,
      default: () => {}
    },
    closable: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isRoutable () {
      if (typeof this.to === 'undefined') return false

      return !_isEmpty(this.to)
    },

    component () {
      return this.isRoutable ? 'router-link' : 'div'
    }
  },

  methods: {
    onClick () {
      if (!this.isRoutable) this.$emit('click')
    }
  }
}
</script>

<style scoped>
.ib-card-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ib-card-wrapper-transition {
  transition: all 0.25s;
}

.ib-card-wrapper-hover:hover {
  cursor: pointer;
  transition: all 0.25s;
  transform: translateY(-2px) translateX(-2px);
  box-shadow: 4px 5px 7px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.06);
}

.ib-card-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
}
</style>
