<template>
  <div class="progress-wrapper position-relative">
    <el-progress
      type="dashboard"
      :percentage="percentageCalculated"
      :format="format"
      :color="progressBarCustomColorMethod"
      :stroke-width="12"
      :show-text="showText"
      :width="180"
    />
    <p v-if="showAdditionalText" class="additional-text mt-0 position-absolute">
      {{ additionalText }}
    </p>
  </div>
</template>

<script>
import MixinProgressBar from '@/mixins/progressBar'

export default {
  name: 'IbProgressDashboard',

  mixins: [
    MixinProgressBar
  ],

  props: {
    percentage: {
      type: Number,
      default: 0
    },
    value: {
      type: [String, Number],
      default: ''
    },
    additionalText: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      percentageCalculated: 0,
      showText: false,
      showAdditionalText: false
    }
  },

  created () {
    this.startAnimation()
  },

  methods: {
    format (percentage) {
      if (percentage === 0) {
        return this.percentage === this.percentageCalculated ? '?' : ''
      } else {
        return this.value
      }
    },

    startAnimation () {
      const that = this

      const promise1 = new Promise((resolve) => {
        setTimeout(() => { that.percentageCalculated = that.percentage; resolve() }, 100)
      })

      const promise2 = new Promise((resolve) => {
        setTimeout(() => { that.showText = true; resolve() }, 600)
      })

      const promise3 = new Promise((resolve) => {
        setTimeout(() => { that.showAdditionalText = true; resolve() }, 1000)
      })

      Promise.all([promise1, promise2, promise3]).then(() => {
        that.$emit('finished-animation')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.additional-text {
  left: 0;
  right: 0;
  bottom: 30px;
}

.el-progress--dashboard {
  ::v-deep {
    .el-progress__text {
      font-size: 35px !important;
      font-weight: bold;
      color: black;
      top: 45%;
    }
  }
}
</style>
