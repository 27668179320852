<template>
  <div class="pill">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IbPill'
}
</script>

<style lang="scss" scoped>
.pill {
  text-transform: uppercase;
  font-family: outfit-medium;
  background-color: $color-yellow;
  color: $color-white;
  line-height: 1;
  margin: 0 auto;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 9px;
  display: inline-block;
}
</style>
