<template>
  <button
    class="ib-button-basic d-flex align-items-center"
    :class="classes"
    :style="{ height: height }"
    @click="onClick"
  >
    <!-- Icon -->
    <template v-if="!$slots['icon'] && icon">
      <i
        class="ib-button-icon mr-1 ib-icon"
        :class="[icon]"
      />
    </template>
    <!-- /Icon -->
    <span class="font-outfit-regular">
      <slot />
    </span>
  </button>
</template>

<script>
/* Colors */
const COLORS = ['blue', 'violet', 'gray']

export default {
  name: 'IbButtonBasic',

  props: {
    height: {
      type: String,
      default: '36px'
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'violet'
    },

    icon: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    filled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        { icon: this.icon },
        { disabled: this.disabled }
      ]
    },

    colorClass () {
      return this.filled ? `ib-button-basic-${this.color}-filled` : `ib-button-basic-${this.color}`
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">

button {
  border: 2px solid #F4F5F6;
  background: $color-white;
  color: #081E4A;
  border-radius: 6px;
  font-size: 16px;
  padding: 0 25px;
  transition: all 0.25s;
  justify-content: center !important;

  @include media-breakpoint-up($md) {
    justify-content: unset !important;
  }

  .ib-button-icon {
    padding-right: 3px;
  }
}

.ib-button-basic {

  &.ib-button-basic-blue {
    background: $color-white;
    border: 2px solid $color-primary;
    color: $color-primary;

    &:hover {
      background: rgb(14, 99, 230, 0.1);
    }
    &:active {
      background: rgba(14, 99, 230, 0.20);
    }
  }

  &.ib-button-basic-gray {
    background: $color-white;
    border: 2px solid #f4f5f6;

    &:hover {
      background: rgba(200, 198, 196, 0.15);
      border: 2px solid rgba(200, 198, 196, 0.0015);
    }
    &:active {
      background: rgba(200, 198, 196, 0.30);
    }
  }

  &.ib-button-basic-violet {
    background: $color-white;
    border: 2px solid $color-ai;
    color: $color-ai;

    &:hover {
      background: rgb(133, 9, 246, 0.1);
    }
    &:active {
      background: rgba(133, 9, 246, 0.20);
    }
  }

  &.ib-button-basic-blue-filled {
    background: $color-primary;
    border: 2px solid $color-primary;
    color: $color-white;

    &:hover {
      background: rgb(14, 99, 230, 0.7);
    }
    &:active {
      background: rgba(14, 99, 230, 0.7);
    }
  }

  &.ib-button-basic-violet-filled {
    background: $color-ai;
    border: 2px solid $color-ai;
    color: $color-white;

    &:hover {
      background: rgb(133, 9, 246, 0.7);
    }
    &:active {
      background: rgba(133, 9, 246, 0.7);
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      background: unset !important;
    }
  }
}
</style>
