export default {
  methods: {
    insertRewardfulCode () {
      const a = document.getElementsByTagName('head')[0]

      const rewardfulScript = document.createElement('script')
      rewardfulScript.type = 'text/javascript'

      const code = '(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,\'rewardful\');'

      try {
        rewardfulScript.appendChild(document.createTextNode(code))
        a.appendChild(rewardfulScript)
      } catch (e) {
        rewardfulScript.text = code
        a.appendChild(rewardfulScript)
      }
    },

    insertRewardful () {
      const a = document.getElementsByTagName('head')[0]
      const r = document.createElement('script')

      r.async = true
      r.src = 'https://r.wdfl.co/rw.js'
      r.setAttribute('data-rewardful', process.env.VUE_APP_REWARDFUL_ID)
      a.appendChild(r)
    }
  }
}
