import axios from 'axios'

const getDefaultState = () => {
  return {
    items: []
  }
}

const state = getDefaultState()

const actions = {
  inviteCollaborator ({ commit, dispatch }, { email, note, ideas, single = false }) {
    return axios.post('collaborators', { email, note, ideas, single })
      .then(response => {
        if (response.data.payload) {
          commit('setCollaborators', response.data.payload.collaborators)
          dispatch('ideaCollection/getAllIdeas', {}, { root: true })
          dispatch('getCollaborators')
        }

        return response
      })
  },
  changeAccess ({ commit }, data) {
    const { collaboratorId, ideas } = data

    return axios.put(`collaborators/${collaboratorId}`, { ideas })
      .then(response => response.data.payload)
      .then(response => {
        if (!ideas.length) {
          commit('setCollaborators', response.collaborators)
        } else {
          commit('updateCollaborators', response.collaborators)
        }

        return response
      })
  },
  getCollaborators ({ commit }) {
    return axios.get('collaborators')
      .then(response => response.data.payload)
      .then(response => {
        commit('setCollaborators', response.collaborators)

        return response
      })
  },
  deleteCollaborator ({ commit }, id) {
    return axios.delete(`collaborators/${id}`)
      .then(response => response.data.payload)
      .then(response => {
        commit('setCollaborators', response.collaborators)

        return response
      })
  }
}

const getters = {
  collaborators: function (state) {
    return state.items
  },
  countCollaborators: function (state) {
    return state.items.length
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  addCollaborator (state, collaborator) {
    state.items.push(collaborator)
  },
  setCollaborators (state, payload) {
    state.items = payload
  },
  updateCollaborators (state, invitation) {
    if (state.items.find(item => item.id === invitation.id)) {
      state.items = state.items.map(item => item.id === invitation.id ? { ...item, ...invitation } : item)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
