const getDefaultState = () => {
  return {
    productView: 'grid',
    promotionView: 'grid',
    competitorsView: 'grid',
    customersView: 'grid',
    startupCostsView: 'list',
    financingView: 'list',
    managementTeamView: 'grid',
    staffPayrollView: 'list',
    revenueStreamsView: 'list',
    directCostsView: 'list',
    marketingBudgetView: 'list',
    otherOverheadsView: 'list',
    distributionsView: 'grid',
    partnersView: 'grid',
    ideaCollectionView: 'grid'
  }
}

const state = getDefaultState()

// noinspection JSUnusedGlobalSymbols
const getters = {}

// noinspection JSUnusedGlobalSymbols
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setProductView (state, viewType) {
    state.productView = viewType
  },

  setPromotionView (state, viewType) {
    state.promotionView = viewType
  },

  setCompetitorsView (state, viewType) {
    state.competitorsView = viewType
  },

  setCustomersView (state, viewType) {
    state.customersView = viewType
  },

  setStartupCostsView (state, viewType) {
    state.startupCostsView = viewType
  },

  setManagementTeamView (state, viewType) {
    state.managementTeamView = viewType
  },

  setFinancingView (state, viewType) {
    state.financingView = viewType
  },

  setStaffPayrollView (state, viewType) {
    state.staffPayrollView = viewType
  },

  setRevenueStreamsView (state, viewType) {
    state.revenueStreamsView = viewType
  },

  setDirectCostsView (state, viewType) {
    state.directCostsView = viewType
  },

  setMarketingBudgetView (state, viewType) {
    state.marketingBudgetView = viewType
  },

  setOtherOverheadsView (state, viewType) {
    state.otherOverheadsView = viewType
  },

  setDistributionsView (state, viewType) {
    state.distributionsView = viewType
  },

  setPartnersView (state, viewType) {
    state.partnersView = viewType
  },

  setIdeaCollectionView (state, viewType) {
    state.ideaCollectionView = viewType
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
