import Vue from 'vue'

class BreakpointHelper {
  constructor () {
    this.VM = new Vue({
      data: () => ({
        width: window.innerWidth,
        currentBreakpoint: null,
        breakpoints: {
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200
        }
      }),

      created () {
        window.addEventListener('resize', this.debounce(this.onResizeHandler))
        this.calculateCurrentBreakpoint()
      },

      destroyed () {
        window.removeEventListener('resize', this.onResizeHandler)
      },

      methods: {
        debounce (func) {
          let timer

          return function (event) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(func, 100, event)
          }
        },

        onResizeHandler () {
          this.width = window.innerWidth
          this.calculateCurrentBreakpoint()
        },

        calculateCurrentBreakpoint () {
          if (this.width < this.breakpoints.sm) {
            this.currentBreakpoint = 'xs'

            return this.currentBreakpoint
          }

          if (this.width < this.breakpoints.md) {
            this.currentBreakpoint = 'sm'

            return this.currentBreakpoint
          }

          if (this.width < this.breakpoints.lg) {
            this.currentBreakpoint = 'md'

            return this.currentBreakpoint
          }

          if (this.width < this.breakpoints.xl) {
            this.currentBreakpoint = 'lg'

            return this.currentBreakpoint
          }
          this.currentBreakpoint = 'xl'

          return this.currentBreakpoint
        }
      }
    })
  }

  get state () {
    return this.VM.$data
  }

  get breakpoints () {
    return this.VM.$data.breakpoints
  }

  get width () {
    return this.VM.$data.width
  }

  get currentBreakpoint () {
    return this.VM.$data.currentBreakpoint
  }

  // Breakpoint getters
  get xs () { return this.currentBreakpoint === 'xs' }
  get sm () { return this.currentBreakpoint === 'sm' }
  get md () { return this.currentBreakpoint === 'md' }
  get lg () { return this.currentBreakpoint === 'lg' }
  get xl () { return this.currentBreakpoint === 'xl' }

  get smAndUp () { return ['sm', 'md', 'lg', 'xl'].includes(this.currentBreakpoint) }
  get mdAndUp () { return ['md', 'lg', 'xl'].includes(this.currentBreakpoint) }
  get lgAndUp () { return ['lg', 'xl'].includes(this.currentBreakpoint) }
  get lgAndDown () { return ['lg', 'md', 'sm', 'xs'].includes(this.currentBreakpoint) }
}

const plugin = {
  Store: BreakpointHelper,
  install (Vue, options) {
    Vue.mixin({
      beforeCreate () {
        this.$breakpoint = options.store
      }
    })
  }
}

export default plugin
