<template>
  <div class="ib-dialog">
    <el-dialog
      :title="title"
      :visible="visible"
      :width="width"
      :fullscreen="fullscreen"
      :top="top"
      :close-on-click-modal="closable"
      :close-on-press-escape="closable"
      :destroy-on-close="destroyOnClose"
      :show-close="showCloseButton"
      :class="{'empty-header': emptyHeader, 'is-fullscreen': fullscreen}"
      @open="$emit('open')"
      @opened="$emit('opened')"
      @close="onClose"
      @closed="$emit('closed')"
    >
      <!-- Title slot -->
      <template #title>
        <slot name="title" />
      </template>
      <!-- /Title slot -->

      <!-- Default slot -->
      <slot />
      <!-- Default slot -->

      <!-- Footer slot -->
      <template #footer>
        <slot name="footer" />
      </template>
      <!-- /Footer slot -->
    </el-dialog>
  </div>
</template>

<script>
import { scrollToTop } from '@/helpers/scrollHelpers'

export default {
  name: 'IbDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: '600px'
    },

    top: {
      type: String,
      default: '15vh'
    },

    title: {
      type: String,
      default: null
    },

    closable: {
      type: Boolean,
      default: true
    },

    showClose: {
      type: Boolean,
      default: true
    },

    destroyOnClose: {
      type: Boolean,
      default: false
    },

    emptyHeader: {
      type: Boolean,
      default: false
    },

    fullscreen: {
      type: Boolean,
      default: false
    },

    noScrollToTopAfterClose: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showCloseButton () {
      return this.closable && this.showClose
    }
  },

  methods: {
    onClose () {
      if (!this.noScrollToTopAfterClose && this.fullscreen) scrollToTop()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.ib-dialog {
  ::v-deep {
    .empty-header {
      .el-dialog__header {
        margin-bottom: 0;
      }
    }

    .el-dialog {
      padding: 20px 20px 25px;

      &__wrapper {
        z-index: 999999999 !important;
        &:not(.is-fullscreen) {
          z-index: 1000000000 !important;
          border-radius: .7rem;
          padding: 15px;
        }
      }

      @include media-breakpoint-up($md) {
        padding: 20px;
      }

      @include media-breakpoint-up($md) {
        padding: 40px 40px 25px;
      }

      &__header,
      &__body,
      &__footer {
        padding: 0;
      }

      &__header {
        margin-bottom: 40px;
      }

      &__body {
        margin-bottom: 45px;
        // Temporary, to hide vertical scrollbar
        //overflow: initial;
      }

      @media (max-width: $lg) {
        width: auto !important;
        margin-top: 0 !important;
      }
    }
  }
}
</style>
