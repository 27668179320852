import axios from 'axios'

const getDefaultState = () => {
  return {
    ideaPlan: {
      id: 0,
      sections: [],
      title: null,
      type: null
    }
  }
}

const state = getDefaultState()

const actions = {
  getIdeaPlan ({ commit }, planId) {
    return axios.get(`idea-plan/${planId}`)
      .then(response => {
        commit('setIdeaPlan', response.data.payload.ideaPlan)
        commit('idea/setMoreDetailsMerge', response.data.payload.notes, { root: true })

        return response
      })
  },
  deleteNote ({ commit }, noteId) {
    return axios.delete(`idea-plan/note/${noteId}`)
      .then(response => {
        commit('setIdeaPlan', response.data.payload.ideaPlan)

        return response
      })
  },
  addNote ({ commit }, note) {
    return axios.post(`idea-plan/add-note/${note.sectionId}`, note)
      .then(response => {
        commit('setIdeaPlan', response.data.payload.ideaPlan)

        return response
      })
  },
  updateNote ({ commit }, note) {
    return axios.put(`idea-plan/edit-note/${note.id}`, note)
      .then(response => {
        commit('setIdeaPlan', response.data.payload.ideaPlan)

        return response
      })
  }
}

const getters = {
  ideaPlan: function (state) {
    return state.ideaPlan
  },
  countIdeaPlanSections: function (state) {
    return state.ideaPlan.sections.length
  },
  notes: (state) => {
    if (state.ideaPlan.sections && state.ideaPlan.sections.length) {
      return state.ideaPlan.sections.reduce((a, item) => {
        return a.concat(item.notes)
      }, []).filter(note => note)
    }

    return []
  },
  isIdeaPlanEmpty: (state, getters) => {
    return !getters.notes.length
  },

  getCountNotes: (state, getters) => {
    if (getters.isIdeaPlanEmpty) return 0

    return getters.notes.filter(item => item.isTouched || !item.isFromTemplate).length
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setIdeaPlan (state, payload) {
    state.ideaPlan = payload
  },
  addSectionNote (state, note) {
    state.ideaPlan.sections[6].notes.push({
      id: note.id,
      color: note.color,
      isFromTemplate: note.isFromTemplate,
      isTouched: note.isTouched,
      text: note.text,
      title: note.title
    })
  },
  updateSection (state, { data, sectionId }) {
    const found = Object.keys(state.ideaPlan.sections).find(key => state.ideaPlan.sections[key].id === sectionId)
    state.ideaPlan.sections[found].notes = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
