import qs from 'qs'

function parseLanguageFromStartLocation (startLocation) {
  if (!startLocation) return undefined

  const parsedString = qs.parse(startLocation)
  const numberOfKeys = Object.keys(parsedString).length

  if (numberOfKeys === 1 && Object.keys(parsedString)[0].includes('lang')) {
    return Object.values(parsedString)[0]
  }

  let indexOfLangKey = null

  Object.keys(parsedString).forEach((key, index) => {
    if (key.includes('lang')) {
      indexOfLangKey = index
    }
  })

  return indexOfLangKey !== null ? Object.values(parsedString)[indexOfLangKey] : undefined
}

export {
  parseLanguageFromStartLocation
}
