import axios from 'axios'

const getDefaultState = () => {
  return {
    catalogueIdeas: []
  }
}

const state = getDefaultState()

const actions = {
  fetchCatalogueIdeas ({ commit }) {
    return axios.get('admin/catalogue/ideas')
      .then(response => response.data.payload)
      .then(response => {
        commit('setCatalogueIdeas', response.catalogueIdeasFormatted)
      })
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setCatalogueIdeas (state, catalogueIdeas) {
    state.catalogueIdeas = catalogueIdeas
  }
}

const getters = {
  getCatalogueIdeas: (state) => {
    return state.catalogueIdeas
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
