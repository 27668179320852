const removedSelectedIdeaError = {
  title: 'Error',
  message: 'Current Idea is not available.',
  type: 'error'
}

function getDefaultErrorMessage (error) {
  const hasMessage = 'message' in error.data

  return {
    title: 'Error',
    message: hasMessage ? error.data.message : 'Something went wrong!',
    type: 'error'
  }
}

export {
  removedSelectedIdeaError,
  getDefaultErrorMessage
}
