export default {
  methods: {
    setupHotjar () {
      const isHotJarEnabled = !!process.env.VUE_APP_HOTJAR
      if (!isHotJarEnabled) return

      const hotJarScript = document.createElement('script')
      hotJarScript.type = 'text/javascript'

      const inlineScript = document.createTextNode(`
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.VUE_APP_HOTJAR},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
      )

      hotJarScript.appendChild(inlineScript)

      document.head.appendChild(hotJarScript)
    }
  }
}
