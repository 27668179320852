export default {
  data () {
    return {
      loaded: {
        staffAndPayroll: false,
        startupCosts: false,
        financing: false,
        revenueStreams: false,
        directCosts: false,
        marketingBudget: false,
        otherOverheads: false,
        profitAndCashFlow: false,
        totalCosts: false
      }
    }
  },

  methods: {
    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    },

    async getStartupCosts () {
      this.loaded.startupCosts = false

      const response = await this.$http.get('ideas/calculation/startup-costs/' + this.getIdea().id)

      if (response.status === 200) {
        this.setStartupCosts(response.data.payload.calculation)
      }

      this.loaded.startupCosts = true
    },

    setStartupCosts (calculation) {
      this.$store.commit('idea/setStartupCosts', calculation.data)
      this.$store.commit('idea/setStartupCostsBreakdown', calculation.breakdown)
      this.$store.commit('idea/setStartupCostsTimeline', calculation.timeline)
      this.$store.commit('idea/setStartupCostsTable', calculation.table)
    },

    async getStaffAndPayroll () {
      this.loaded.staffAndPayroll = false

      const response = await this.$http.get('ideas/calculation/staff-and-payroll/' + this.getIdea().id)

      if (response.status === 200) {
        this.setStaffAndPayroll(response.data.payload.calculation)
      }

      this.loaded.staffAndPayroll = true
    },

    setStaffAndPayroll (calculation) {
      this.$store.commit('idea/setStaffAndPayroll', calculation.data)
      this.$store.commit('idea/setStaffAndPayrollBreakdown', calculation.breakdown)
      this.$store.commit('idea/setStaffAndPayrollTimeline', calculation.timeline)
      this.$store.commit('idea/setStaffAndPayrollTable', calculation.table)
    },

    async getFinancing () {
      this.loaded.financing = false

      const response = await this.$http.get('ideas/calculation/financing/' + this.getIdea().id)

      if (response.status === 200) {
        this.setFinancing(response.data.payload.calculation)
      }

      this.loaded.financing = true
    },

    setFinancing (calculation) {
      this.$store.commit('idea/setFinancing', calculation.data)
      this.$store.commit('idea/setFinancingBreakdown', calculation.breakdown)
      this.$store.commit('idea/setFinancingTimeline', calculation.timeline)
      this.$store.commit('idea/setFinancingTable', calculation.table)
    },

    async getRevenueStreams () {
      this.loaded.revenueStreams = false

      const response = await this.$http.get('ideas/calculation/revenue-streams/' + this.getIdea().id)

      if (response.status === 200) {
        this.setRevenueStreams(response.data.payload.calculation)
      }

      this.loaded.revenueStreams = true
    },

    setRevenueStreams (calculation) {
      this.$store.commit('idea/setRevenueStreams', calculation.data)
      this.$store.commit('idea/setRevenueStreamsBreakdown', calculation.breakdown)
      this.$store.commit('idea/setRevenueStreamsTimeline', calculation.timeline)
      this.$store.commit('idea/setRevenueStreamsTable', calculation.table)
    },

    async getDirectCosts () {
      this.loaded.directCosts = false
      const response = await this.$http.get(`ideas/calculation/direct-costs/${this.getIdea().id}`)

      if (response.status === 200) {
        this.setDirectCosts(response.data.payload.calculation)
      }

      this.loaded.directCosts = true
    },

    setDirectCosts (calculation) {
      this.$store.commit('idea/setDirectCosts', calculation.data)
      this.$store.commit('idea/setDirectCostsBreakdown', calculation.breakdown)
      this.$store.commit('idea/setDirectCostsTimeline', calculation.timeline)
      this.$store.commit('idea/setDirectCostsTable', calculation.table)
    },

    async getMarketingBudget () {
      this.loaded.marketingBudget = false
      const response = await this.$http.get('ideas/calculation/marketing-budget/' + this.getIdea().id)

      if (response.status === 200) {
        this.setMarketingBudget(response.data.payload.calculation)
      }

      this.loaded.marketingBudget = true
    },

    setMarketingBudget (calculation) {
      this.$store.commit('idea/setMarketingBudget', calculation.data)
      this.$store.commit('idea/setMarketingBudgetBreakdown', calculation.breakdown)
      this.$store.commit('idea/setMarketingBudgetTimeline', calculation.timeline)
      this.$store.commit('idea/setMarketingBudgetTable', calculation.table)
    },

    async getOtherOverheads () {
      this.loaded.otherOverheads = false

      const response = await this.$http.get('ideas/calculation/other-overheads/' + this.getIdea().id)

      if (response.status === 200) {
        this.setOtherOverheads(response.data.payload.calculation)
      }

      this.loaded.otherOverheads = true
    },

    setOtherOverheads (calculation) {
      this.$store.commit('idea/setOtherOverheads', calculation.data)
      this.$store.commit('idea/setOtherOverheadsBreakdown', calculation.breakdown)
      this.$store.commit('idea/setOtherOverheadsTimeline', calculation.timeline)
      this.$store.commit('idea/setOtherOverheadsTable', calculation.table)
    },

    async getProfitAndCashFlow () {
      this.loaded.profitAndCashFlow = false

      const response = await this.$http.get('ideas/calculation/profit-and-cash-flow/' + this.getIdea().id)

      if (response.status === 200) {
        this.setProfitAndCashFlow(response.data.payload.calculation)
      }

      this.loaded.profitAndCashFlow = true
    },

    setProfitAndCashFlow (calculation) {
      this.$store.commit('idea/setProfitAndCashFlow', calculation)
    },

    async getTotalCosts () {
      this.loaded.totalCosts = false

      const response = await this.$http.get('ideas/calculation/total-costs/' + this.getIdea().id)

      if (response.status === 200) {
        this.setTotalCosts(response.data.payload.calculation)
      }

      this.loaded.totalCosts = true
    },

    setTotalCosts (calculation) {
      this.$store.commit('idea/setTotalCosts', calculation)
    },

    getAllCalculations () {
      return Promise.all([
        this.getTotalCosts(),
        this.getProfitAndCashFlow(),
        this.getRevenueStreams(),
        this.getFinancing(),
        this.getStartupCosts(),
        this.getStaffAndPayroll(),
        this.getDirectCosts(),
        this.getMarketingBudget(),
        this.getOtherOverheads()
      ])
    }
  }
}
