export default {
  computed: {
    isPartneroPartneroPartnerExists () {
      return !!this.$cookies.get('partnero_partner')
    }
  },

  methods: {
    setupPartnero () {
      (function (p, t, n, e, r, o) {
        p.__partnerObject = r; function f () {
          const c = { a: arguments, q: [] }; const r = this.push(c)

          return typeof r !== 'number' ? r : f.bind(c.q)
        }
        f.q = f.q || []; p[r] = p[r] || f.bind(f.q); p[r].q = p[r].q || f.q; o = t.createElement(n)
        const _ = t.getElementsByTagName(n)[0]; o.async = 1; o.src = e + '?v' + (~~(new Date().getTime() / 1e6))
        _.parentNode.insertBefore(o, _)
      })(window, document, 'script', 'https://app.partnero.com/js/universal.js', 'po')
      // eslint-disable-next-line no-undef
      window.po('settings', 'assets_host', 'https://assets.partnero.com')
      // eslint-disable-next-line no-undef
      window.po('program', `${process.env.VUE_APP_PARTNERO_PROGRAM_ID}`, 'load')
    },

    createPartneroCustomer (user) {
      if (this.isPartneroPartneroPartnerExists) {
        window.po('customers',
          'signup', {
            data: {
              key: user.email,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email
            }
          }
        )
      }
    }
  }
}
