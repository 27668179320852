<template>
  <el-popover
    v-model="visible"
    placement="bottom"
    popper-class="ib-color-picker-popover"
    :width="290"
    trigger="click"
  >
    <div
      slot="reference"
      class="cursor-pointer no-tap-highlight"
    >
      <div
        class="palette m-auto"
        :style="{backgroundColor: color}"
      >
        <i class="ib-icon-paint-palette" />
      </div>

    </div>

    <div class="ib-color-picker-content">
      <twitter
        v-model="currentColor"
        :default-colors="defaultColors"
        triangle="hide"
        width="500"
        @input="onInput($event)"
      />
    </div>

  </el-popover>
</template>

<script>
import { Twitter } from 'vue-color'
import colorPickerColors from '@/data/colorPickerColors'

export default {
  name: 'IbColorPicker',

  components: {
    Twitter
  },

  props: {
    color: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      visible: false,
      defaultColors: colorPickerColors,
      currentColor: ''
    }
  },

  mounted () {
    this.currentColor = this.color
  },

  methods: {
    onInput (event) {
      this.currentColor = event.hex
      this.visible = false
      this.$emit('change', event.hex)
    }
  }
}
</script>

<style scoped lang="scss">
.palette {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 30px;
}

.ib-color-picker-content {
  ::v-deep {
    .vc-twitter {
      box-shadow: none;

      .vc-twitter-body {
        padding: 0;

        //.vc-twitter-hash,
        //.vc-editable-input {
        //  display: none;
        //}

        .vc-twitter-clear {
          clear: none;
        }
      }
    }
  }
}
</style>
