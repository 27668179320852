<template>
  <span
    class="ib-tag text-white rounded"
    :class="classes"
  >
    <slot />
  </span>
</template>

<script>
/** Colors **/
const COLORS = ['blue', 'yellow', 'black']

export default {
  name: 'IbTag',

  props: {
    variant: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'yellow'
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass
      ]
    },

    colorClass () {
      return `ib-tag-${this.variant}`
    }
  }
}
</script>

<style scoped lang="scss">
.ib-tag {
  font-size: 12px;
  padding: 1px 5px;
  white-space: pre;

  &.ib-tag-yellow {
    background-color: $color-yellow;
  }

  &.ib-tag-blue {
    background-color: $color-primary;
  }

  &.ib-tag-black {
    background-color: black;
  }
}
</style>
