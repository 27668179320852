<template>
  <el-select
    v-model="dataValue"
    :placeholder="placeholder"
    :popper-append-to-body="false"
    :disabled="disabled"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <!-- Slot -->
    <slot />
    <!-- /Slot -->
  </el-select>
</template>

<script>
export default {
  name: 'IbSelect',

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || typeof value === 'number' || value === null,
      default: null
    },

    filterable: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dataValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>
