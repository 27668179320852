const getDefaultState = () => {
  return {
    menuCollapsed: false,
    home: {
      gettingStartedExpanded: true
    },
    handWritings: {
      financialPlanOverview: true
    }
  }
}

const state = getDefaultState()

const getters = {
  isMenuCollapsed (state) {
    return !!state.menuCollapsed
  },

  getGettingStartedExpanded (state) {
    return state.home.gettingStartedExpanded
  },

  getHandWritings (state) {
    return state.handWritings
  }
}

const mutations = {
  setMenuCollapsedToggle (state) {
    state.menuCollapsed = !state.menuCollapsed
  },

  setGettingStartedExpandedState (state, data) {
    state.home.gettingStartedExpanded = data
  },

  setHandWriting (state, { key, value }) {
    state.handWritings[key] = value
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
