<template>
  <div class="d-flex align-items-center" :title="userFullName">
    <div
      v-if="imageUrl"
      class="avatar-base avatar-image"
      :class="sizeClass"
      :style="{'background-image': `url(${imageUrl})`}"
    />
    <div
      v-else
      class="avatar-base avatar-default d-flex justify-content-center align-items-center man"
      :class="sizeClass"
      :style="{'background-image': `url(${defaultImage})`}"
    >
      <span v-if="initials" class="text-uppercase">{{ userInitials }}</span>
    </div>
  </div>
</template>

<script>
import { getImageBySize } from '@/helpers/imageSizeHelper'

const SIZES = ['sm', 'md', 'lg', 'xl']

export default {
  name: 'IbUserAvatar',

  props: {
    user: {
      type: Object,
      default: null
    },

    initials: {
      type: Boolean,
      default: true
    },

    showDefaultImage: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      validator: value => {
        return SIZES.includes(value)
      },
      default: SIZES[1]
    }
  },

  computed: {
    imageSize () {
      return this.size === 'xl' ? 'medium' : 'small'
    },
    imageUrl () {
      if (this.user) {
        // ToDo Remove this quick fix and resolve in store (user.image)
        let sizes = null
        const { image, images } = this.user

        if (image && typeof image === 'object') {
          sizes = image.sizes
        }
        if (typeof images === 'object') {
          sizes = images
        }

        return sizes ? getImageBySize(sizes, this.imageSize) : null
      }

      return null
    },
    userInitials () {
      if (this.user) {
        const { firstName, lastName } = this.user

        return firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : null
      }

      return null
    },
    userFullName () {
      if (this.user) {
        const { firstName, lastName, email } = this.user

        return this.user ? (firstName && lastName ? `${firstName} ${lastName}` : email) : null
      }

      return null
    },
    defaultImage () {
      if (this.user && this.user.gender) {
        return this.user.gender === 'male' ? require('../../assets/img/icons/man-icon.svg') : require('../../assets/img/icons/woman-icon.svg')
      }

      return this.showDefaultImage ? require('../../assets/img/icons/profile-icon.svg') : null
    },
    sizeClass () {
      return `image-size-${this.size}`
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-image {
  object-fit: cover;
  border-radius: 50%;
}

.avatar-base {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #f3f4f5;
}

.avatar-default {
  background-color: #f5f6f6;
  background-size: 60%;
  color: #0b42b0;
  letter-spacing: 1.5px;
}

.avatar-default span {
  opacity: 0.5;
  font-size: 14px;
  line-height: 1.57;
}

.image-size-sm {
  width: 32px;
  height: 32px;
}

.image-size-md {
  width: 50px;
  height: 50px;
}

.image-size-lg {
  width: 70px;
  height: 70px;
}

.image-size-xl {
  width: 140px;
  height: 140px;
  span {
    font-size: 40px;
  }
}
</style>
