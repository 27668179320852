import axios from 'axios'

const getDefaultState = () => {
  return {
    ownership: {
      id: 0,
      type: null,
      otherType: null,
      owners: [],
      isFromTemplate: false,
      isTouched: false
    }
  }
}

const state = getDefaultState()

const actions = {
  updateOwnership ({ commit, dispatch }, { ideaId, type, otherType }) {
    return axios.put(`story-mode/set-up/ownership/${ideaId}`, { type, otherType })
      .then(response => {
        if (response.data.payload) {
          commit('setOwnership', response.data.payload.ownership)
        }

        return response
      })
  },

  updateOwners ({ commit }, { ideaId, payload }) {
    return axios.put(`story-mode/set-up/ownership/owners/${ideaId}`, { data: payload })
      .then((response) => {
        commit('setOwners', response.data.payload.owners)

        return response
      })
  }
}

const getters = {
  ownership: function (state) {
    return state.ownership
  },

  getOwners: (state) => {
    return state.ownership.owners
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setOwnership (state, payload) {
    state.ownership = payload ?? getDefaultState().ownership
  },
  setOwners (state, payload) {
    state.ownership.owners = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
