import axios from 'axios'

const getDefaultState = () => {
  return {
    ideas: [],
    sharedIdeas: []
  }
}

const state = getDefaultState()

const actions = {
  getAllIdeas ({ commit }) {
    axios.get('ideas')
      .then(response => response.data.payload)
      .then(response => {
        commit('setIdeas', response)
        commit('setSharedIdeas', response.sharedIdeas)
      })
  },
  deleteIdea ({ commit }, id) {
    return axios.delete(`ideas/${id}`)
      .then(response => {
        commit('removeIdea', id)
        commit('user/decreaseIdeasCount', null, { root: true })
        commit('user/collaborators/setCollaborators', response.data.payload.collaborators, { root: true })

        return response
      })
  }
}

// noinspection JSUnusedGlobalSymbols
const getters = {
  getIdeas: (state) => {
    return state.ideas
  },

  getIdeasCount: (state) => {
    return state.ideas.length
  },

  getSharedIdeas: (state) => {
    return state.sharedIdeas
  },

  getSharedIdeasCount: (state) => {
    return state.sharedIdeas.length
  }
}

// noinspection JSUnusedGlobalSymbols
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setIdeas (state, payload) {
    state.ideas = payload.ideas
  },

  addIdea (state, idea) {
    state.ideas.unshift(idea)
  },

  copyIdea (state, idea) {
    state.ideas.unshift(idea)
  },

  updateIdea (state, idea) {
    const index = state.ideas.indexOf(
      state.ideas.find(ideaToUpdate => ideaToUpdate.id === idea.id)
    )

    state.ideas.splice(index, 1, idea)
  },

  setSharedIdeas (state, data) {
    state.sharedIdeas = data
  },

  addInvitation (state, data) {
    const index = state.ideas.indexOf(
      state.ideas.find(idea => idea.id === data.ideaId)
    )
    const editedIdea = { ...state.ideas[index] }
    editedIdea.collaborators.push(data.invitation)

    state.ideas.splice(index, 1, editedIdea)
  },

  changeCompletedStep (state, payload) {
    let ideasIndex = -1
    let sharedIndex = -1

    if (state.ideas.length) {
      ideasIndex = state.ideas.indexOf(
        state.ideas.find(ideaToUpdate => ideaToUpdate.id === payload.id)
      )
    }

    if (state.sharedIdeas.length) {
      sharedIndex = state.sharedIdeas.indexOf(
        state.sharedIdeas.find(ideaToUpdate => ideaToUpdate.idea.id === payload.id)
      )
    }

    if (ideasIndex === -1 || sharedIndex === -1) return

    const source = ideasIndex !== -1 ? 'ideas' : 'sharedIdeas'
    const index = ideasIndex !== -1 ? ideasIndex : sharedIndex

    const editedIdea = ideasIndex !== -1 ? { ...state[source][index] } : { ...state[source][index].idea }
    editedIdea.completedSteps[payload.step] = payload.completed

    state[source].splice(index, 1, editedIdea)
  },

  removeIdea (state, ideaId) {
    const index = state.ideas.indexOf(
      state.ideas.find(ideaToRemove => ideaToRemove.id === ideaId)
    )

    state.ideas.splice(index, 1)
  },

  removeSharedIdea (state, ideaId) {
    const index = state.sharedIdeas.indexOf(
      state.sharedIdeas.find(ideaToRemove => ideaToRemove.idea.id === ideaId)
    )

    state.sharedIdeas.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
