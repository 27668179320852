<template>
  <div
    class="d-flex flex-sm-row flex-column-reverse flex-grow-1 flex-sm-grow-0 align-items-sm-center"
    :class="classes"
  >
    <a
      v-if="visibleCancelBtn"
      class="mr-0 mr-sm-3 cancel-btn text-center"
      @click="$emit('cancel')"
    >{{ cancelBtnText }}</a>
    <ib-button
      v-if="visibleSubmitBtn"
      :loading="loading"
      :disabled="disableSubmitBtn"
      class="text-uppercase mb-sm-0"
      :class="submitBtnClasses"
      :size="buttonSize"
      :variant="variantButton"
      :outline="outlineButton"
      submit-button
      @click="$emit('submit')"
    >
      {{ submitBtnText }}
    </ib-button>
  </div>
</template>

<script>
export default {
  name: 'IbDialogFooter',

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    buttonSize: {
      type: String,
      default: 'sm'
    },

    submitBtnText: {
      type: String,
      default: function () {
        return this.$t('save')
      }
    },

    cancelBtnText: {
      type: String,
      default: function () {
        return this.$t('cancel')
      }
    },

    submitBtnClass: {
      type: String,
      default: ''
    },

    variantButton: {
      type: String,
      default: 'blue'
    },

    outlineButton: {
      type: Boolean,
      default: false
    },

    disableSubmitBtn: {
      type: Boolean,
      default: false
    },

    visibleSubmitBtn: {
      type: Boolean,
      default: true
    },

    visibleCancelBtn: {
      type: Boolean,
      default: true
    },

    spaceBetweenButtons: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.justifyClass
      ]
    },

    submitBtnClasses () {
      return [
        this.marginClass,
        this.submitButtonClass
      ]
    },

    marginClass () {
      return this.visibleCancelBtn ? 'mb-3' : null
    },

    submitButtonClass () {
      return this.submitBtnClass ? this.submitBtnClass : null
    },

    justifyClass () {
      return this.spaceBetweenButtons ? 'justify-content-sm-between' : 'justify-content-sm-end'
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-btn {
  color: #3e474f;
  text-decoration: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    text-decoration: underline;
    transition: all 0.25s;
  }
}
</style>
